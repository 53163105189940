import { MerchantCheckoutType } from '@/Templates/enums/templateEnums';
import { IAlterMerchantPortalSettingRequest } from '@/interfaces/Requests/Requests';
import { useAlterMerchantBrowserTabIconApi, useAlterMerchantIconApi, useAlterMerchantPortalSettingApi } from '@/lib/api/merchants';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { PlusOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { Button, Col, Flex, Form, Input, InputNumber, Modal, Popover, Row, Select, Switch, Upload } from 'antd';
import Title from 'antd/es/typography/Title';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { PortalSettingPageContext } from '../Contexts/PortalSettingPageContext';
import { useUploadImageApi } from '@/lib/api/images';
import { ImageUseageType } from '@/enums/Enums';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export interface IPortalGlobalSystemSettingProp { }
export interface IPortalGlobalSystemSettingRef {
    onRefresh: () => void;
}

const PortalGlobalSystemSetting = React.memo(
    React.forwardRef(
        (
            props: IPortalGlobalSystemSettingProp,
            ref: React.ForwardedRef<IPortalGlobalSystemSettingRef | undefined>
        ) => {
            const [form] = Form.useForm();
            const {
                merchantId,
                messageApi,
                merchantPortalOptionSettingMutation,
            } = useContext(GlobalContext);
            const { supportedLanguages, translate, i18nLanguage } = useContext(TranslationContext);
            const { abortController } = useContext(PortalSettingPageContext);
            const [imageChanged, setImageChanged] = useState(false);
            const [fileList, setFileList] = useState<UploadFile[]>([]);
            const [browserTabImageChanged, setBrowserTabImageChanged] = useState(false);
            const [browserTabFileList, setBrowserTabFileList] = useState<UploadFile[]>([]);
            const [alterRequest, setAlterRequest] = useState<IAlterMerchantPortalSettingRequest>({
                merchantName: merchantPortalOptionSettingMutation.data?.result?.merchantName,
                merchantLogo: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath,
                merchantBrowserTabIcon: merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath,
                portalLanguage: merchantPortalOptionSettingMutation.data?.result?.portalLanguage,
                merchantCheckoutType: merchantPortalOptionSettingMutation.data?.result?.merchantCheckoutType,
                isEnableInovice: merchantPortalOptionSettingMutation.data?.result?.isEnableInovice,
                orderMinimumAmountLimit: merchantPortalOptionSettingMutation.data?.result?.orderMinimumAmountLimit,
                isClosed: merchantPortalOptionSettingMutation.data?.result?.isClosed,
                merchantId: merchantId,
            } as IAlterMerchantPortalSettingRequest);

            useEffect(() => {
                if (merchantPortalOptionSettingMutation.data?.result?.merchantIconPath) {
                    setFileList([
                        {
                            uid: uuidv4(),
                            name: 'merchantIcon',
                            status: 'done',
                            url: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath,
                        },
                    ]);
                } else {
                    setFileList([]);
                }

                if (merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath) {
                    setBrowserTabFileList([
                        {
                            uid: uuidv4(),
                            name: 'browserTabIcon',
                            status: 'done',
                            url: merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath,
                        },
                    ]);
                } else {
                    setBrowserTabFileList([]);
                }
            }, [merchantPortalOptionSettingMutation.data?.result?.merchantIconPath, merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath]);


            const alterMerchantPortalSettingApiMutation =
                useMutation(
                    async (request: IAlterMerchantPortalSettingRequest) =>
                        await useAlterMerchantPortalSettingApi(
                            request,
                            abortController.current?.signal
                        ),
                    {
                        onSuccess: (response) => {
                            if (response.isSuccess) {
                                messageApi.success(translate('Operation success'));
                            } else {
                                messageApi.error(translate(response.message || 'Operation failed'));
                            }
                        },
                    }
                );


            useEffect(() => {
                if (merchantPortalOptionSettingMutation.data?.result) {
                    setAlterRequest({
                        merchantName: merchantPortalOptionSettingMutation.data?.result?.merchantName,
                        portalLanguage: merchantPortalOptionSettingMutation.data?.result?.portalLanguage,
                        merchantCheckoutType: merchantPortalOptionSettingMutation.data?.result?.merchantCheckoutType,
                        isEnableInovice: merchantPortalOptionSettingMutation.data?.result?.isEnableInovice,
                        orderMinimumAmountLimit: merchantPortalOptionSettingMutation.data?.result?.orderMinimumAmountLimit,
                        isClosed: merchantPortalOptionSettingMutation.data?.result?.isClosed,
                        merchantLogo: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath,
                        merchantBrowserTabIcon: merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath,
                    });
                    form.setFieldsValue({
                        merchantName: merchantPortalOptionSettingMutation.data?.result?.merchantName,
                        portalLanguage: merchantPortalOptionSettingMutation.data?.result?.portalLanguage,
                        merchantCheckoutType: merchantPortalOptionSettingMutation.data?.result?.merchantCheckoutType,
                        isEnableInovice: merchantPortalOptionSettingMutation.data?.result?.isEnableInovice,
                        orderMinimumAmountLimit: merchantPortalOptionSettingMutation.data?.result?.orderMinimumAmountLimit,
                        isClosed: merchantPortalOptionSettingMutation.data?.result?.isClosed,
                        merchantLogo: merchantPortalOptionSettingMutation.data?.result?.merchantIconPath,
                        merchantBrowserTabIcon: merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath,
                    });
                }
            }, [merchantPortalOptionSettingMutation.data?.result, merchantId]);

            useEffect(() => {
                form.setFieldsValue({
                    merchantName: alterRequest.merchantName,
                    portalLanguage: alterRequest.portalLanguage,
                    merchantCheckoutType: alterRequest.merchantCheckoutType,
                    isEnableInovice: alterRequest.isEnableInovice,
                    orderMinimumAmountLimit: alterRequest.orderMinimumAmountLimit,
                });
            }, [alterRequest, form]);

            useImperativeHandle(ref, () => ({
                onRefresh() {
                    merchantPortalOptionSettingMutation.mutate({ merchantId: merchantId! });
                },
            }));

            const handleBeforeUpload = (file: RcFile) => {
                const formData = new FormData();
                formData.append('UseageType', ImageUseageType.MerchantPortalStyle);
                formData.append('file', file);

                upLoadImageMutation.mutate(formData, {
                    onSuccess: (response) => {
                        if (response.isSuccess && response.result) {
                            setFileList([{
                                uid: uuidv4(),
                                name: file.name,
                                status: 'done',
                                url: response.result,
                            }]);
                            setAlterRequest(prev => ({
                                ...prev,
                                merchantLogo: response.result || ''
                            }));
                        } else {
                            messageApi.error(translate('Upload failed'));
                        }
                    },
                    onError: () => {
                        messageApi.error(translate('Upload failed'));
                    }
                });

                return false; // 防止自動上傳
            };

            const handleRemove = () => {
                setFileList([]);
                setImageChanged(true);
                setAlterRequest(prev => ({
                    ...prev,
                    merchantLogo: '',
                }));
            };

            const handleBrowserTabBeforeUpload = (file: RcFile) => {
                const formData = new FormData();
                formData.append('UseageType', ImageUseageType.MerchantPortalStyle);
                formData.append('file', file);

                upLoadImageMutation.mutate(formData, {
                    onSuccess: (response) => {
                        if (response.isSuccess && response.result) {
                            setBrowserTabFileList([{
                                uid: uuidv4(),
                                name: file.name,
                                status: 'done',
                                url: response.result,
                            }]);
                            setAlterRequest(prev => ({
                                ...prev,
                                merchantBrowserTabIcon: response.result || ''
                            }));
                        } else {
                            messageApi.error(translate('Upload failed'));
                        }
                    },
                    onError: () => {
                        messageApi.error(translate('Upload failed'));
                    }
                });

                return false;
            };

            const handleBrowserTabRemove = () => {
                setBrowserTabFileList([]);
                setBrowserTabImageChanged(true);
                setAlterRequest(prev => ({
                    ...prev,
                    merchantBrowserTabIcon: '',
                }));
            };

            const upLoadImageMutation = useMutation(async (request: FormData) => await useUploadImageApi(request));

            const handleSave = async () => {
                try {

                    // 商戶關閉確認對話框邏輯
                    if (alterRequest.isClosed !== merchantPortalOptionSettingMutation.data?.result?.isClosed) {
                        const confirmed = await new Promise((resolve) => {
                            Modal.confirm({
                                title: translate('Warning'),
                                content: i18nLanguage === 'zh_TW'
                                    ? '關閉商戶將導致用戶無法訪問您的商店。確定要繼續嗎？'
                                    : 'Closing the merchant will prevent users from accessing your store. Are you sure you want to continue?',
                                okText: translate('Yes'),
                                cancelText: translate('No'),
                                onOk: () => resolve(true),
                                onCancel: () => resolve(false)
                            });
                        });

                        if (!confirmed) {
                            setAlterRequest({
                                ...alterRequest,
                                isClosed: merchantPortalOptionSettingMutation.data?.result?.isClosed ?? false
                            });
                            form.setFieldsValue({
                                isClosed: merchantPortalOptionSettingMutation.data?.result?.isClosed ?? false
                            });
                            return;
                        }
                    }
                    // 調用API
                    await alterMerchantPortalSettingApiMutation.mutateAsync(alterRequest, {
                        onSuccess: () => {
                            messageApi.success(translate('Operation success'));
                            merchantPortalOptionSettingMutation.mutate({ merchantId: merchantId! });
                        },
                        onError: () => {
                            messageApi.error(translate('Operation failed'));
                        }
                    });

                } catch (error) {
                    messageApi.error(translate('Operation failed'));
                }
            };

            const MinimumAmountLimitPopover = () => {
                const content =
                    i18nLanguage === 'zh_TW'
                        ? '此欄位功能為買家需要達到最低金額才能下單'
                        : 'This field sets the Minimum order amount required for buyers to place an order';

                return (
                    <Popover content={content} title={translate('Minimum order amount')}>
                        <QuestionCircleTwoTone style={{ marginLeft: 8 }} />
                    </Popover>
                );
            };

            return (
                merchantPortalOptionSettingMutation.data?.result && (
                    <>
                        <Row>
                            <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Title level={5}>{translate('Merchant logo')}</Title>
                                    <Upload
                                        className='merchant-logo-upload'
                                        key={merchantPortalOptionSettingMutation.data?.result?.merchantIconPath || 'upload'}
                                        listType="picture-card"
                                        beforeUpload={handleBeforeUpload}
                                        showUploadList={false}
                                        onRemove={handleRemove}
                                    >
                                        {fileList.length >= 1 ? (
                                            <LazyLoadImage
                                                src={fileList[0].url}
                                                alt="merchantIcon"
                                                effect="blur"
                                                height={'95%'}
                                                width={'95%'}
                                            />
                                        ) : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>{translate('Upload')}</div>
                                            </div>
                                        )}
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Title level={5}>{translate('Browser Tab Icon')}</Title>
                                    <Upload
                                        className='browser-tab-icon-upload'
                                        key={merchantPortalOptionSettingMutation.data?.result?.merchantBrowserTabIconPath || 'browser-tab-upload'}
                                        listType="picture-card"
                                        beforeUpload={handleBrowserTabBeforeUpload}
                                        showUploadList={false}
                                        onRemove={handleBrowserTabRemove}
                                    >
                                        {browserTabFileList.length >= 1 ? (
                                            <LazyLoadImage
                                                src={browserTabFileList[0].url}
                                                alt="browserTabIcon"
                                                effect="blur"
                                                height={'95%'}
                                                width={'95%'}
                                            />
                                        ) : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>{translate('Upload')}</div>
                                            </div>
                                        )}
                                    </Upload>
                                </div>
                            </Col>
                        </Row>
                        <Flex justify='center' style={{ width: '100%' }}>
                            <Row>
                                <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <Form form={form} layout="vertical">
                                            <Form.Item
                                                name="merchantName"
                                                label={`${translate('Merchant')} ${translate('Name')}`}
                                            >
                                                <Input
                                                    onChange={(e) =>
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            merchantName: e.target.value,
                                                        })
                                                    }
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                name="isClosed"
                                                label={translate('Merchant portal status')}
                                                valuePropName="checked"
                                                initialValue={false}
                                            >
                                                <Switch
                                                    checkedChildren={translate('Closed')}
                                                    unCheckedChildren={translate('Open')}
                                                    style={{
                                                        backgroundColor: alterRequest.isClosed ? '#ff4d4f' : '#52c41a'
                                                    }}
                                                    onChange={(checked: boolean) => {
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            isClosed: checked,
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item name="portalLanguage" label={translate('Portal default language')}>
                                                <Select
                                                    onChange={(value) =>
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            portalLanguage: value,
                                                        })
                                                    }
                                                >
                                                    {supportedLanguages.map((language) => (
                                                        <Select.Option key={language} value={language}>
                                                            {translate(language)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="merchantCheckoutType" label={translate('Portal checkout type')}>
                                                <Select
                                                    onChange={(value) =>
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            merchantCheckoutType: value,
                                                        })
                                                    }
                                                >
                                                    {Object.keys(MerchantCheckoutType).map((key) => (
                                                        <Select.Option key={key} value={key}>
                                                            {translate(key)}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="orderMinimumAmountLimit"
                                                label={
                                                    <span>
                                                        {translate('Minimum order amount')}
                                                        <MinimumAmountLimitPopover />
                                                    </span>
                                                }
                                            >
                                                <InputNumber
                                                    min={0}
                                                    onChange={(value) =>
                                                        setAlterRequest({
                                                            ...alterRequest,
                                                            orderMinimumAmountLimit: value || 0,
                                                        })
                                                    }
                                                />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Flex>
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Button
                                        loading={alterMerchantPortalSettingApiMutation.isLoading}
                                        type="primary"
                                        onClick={handleSave}
                                    >
                                        {translate('Save')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </>
                )
            );
        }
    )
);

export default PortalGlobalSystemSetting;
