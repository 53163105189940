import React, { Suspense } from 'react';
import { IDynamicComponent } from '@/Templates/interfaces/templatesInterfaces';

interface DynamicPageTemplateProps {
    components: IDynamicComponent[];
}

const DynamicPageTemplate: React.FC<DynamicPageTemplateProps> = ({ components }) => {
    if (!components?.length) {
        return null;
    }

    return (
        <>
            {components.map((component, index) => {
                const ComponentTemplate = React.lazy(() =>
                    import(`@/Templates/Shop/Components/${component.name}/Template_${component.style}.tsx`)
                );
                return (
                    <div key={`${component.name}-${index}`} style={{ width: '100%' }}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <ComponentTemplate config={{ ...component.values }} />
                        </Suspense>
                    </div>
                );
            })}
        </>
    );
};

export default DynamicPageTemplate; 