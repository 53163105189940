import JSONBig from 'json-bigint';

/**
 * SOLID 原則：單一職責、明確分工
 * 此程式碼示範：
 * - PrinterService 負責與 USB 裝置溝通 (SRP：Single Responsibility Principle)
 * - PrinterManager 中包含對外呼叫列印流程，並處理可能錯誤 
 *   透過建構子注入(Dependency Injection)，使系統更易測試與維護
 */

/** 
 * 定義裝置介面的資訊結構 (方便型別檢查＆程式維護)
 */
interface DeviceInterfaceInfo {
    productName: string;
    manufacturerName: string;
    configurations: {
        configurationValue: number;
        interfaces: {
            interfaceNumber: number;
            alternates: {
                alternateSetting: number;
                interfaceClass: number;
                interfaceSubclass: number;
                interfaceProtocol: number;
                endpoints: {
                    endpointNumber: number;
                    direction: USBDirection;
                    type: USBEndpointType;
                }[];
            }[];
        }[];
    }[];
}

/**
 * 負責與 Printer 裝置溝通的 Service
 */
export class PrinterService {
    /**
     * 檢查瀏覽器是否支援 WebUSB，且必須在 HTTPS 或 localhost 執行
     */
    private checkWebUSBSupport(): void {
        if (!navigator.usb) {
            throw new Error('此瀏覽器/版本不支援 WebUSB');
        }
        const isSecureContext = window.isSecureContext;
        if (!isSecureContext) {
            throw new Error('必須在 HTTPS 或 localhost 中才能使用 WebUSB');
        }
    }

    /**
     * 要求使用者選擇 USB 裝置後，打開並 Claim Printer 介面
     * @param filters 選擇要篩選的裝置 (ex: vendorId)
     * @returns { device, interfaceNumber, endpointNumber }
     *          - device: 使用者選擇的 USBDevice
     *          - interfaceNumber: 找到的 printer interface
     *          - endpointNumber: 找到的 out 端點
     */
    public async requestAndConnectPrinter(filters: USBDeviceFilter[] = []): Promise<{
        device: USBDevice;
        interfaceNumber: number;
        endpointNumber: number;
    }> {
        this.checkWebUSBSupport();

        // 1. 要求使用者選擇裝置
        const device = await navigator.usb.requestDevice({ filters });

        // 2. 開啟裝置
        await device.open();

        // 預設取第一個 configuration (通常為 1，若非 1 請依實際調整)
        if (device.configuration === null) {
            await device.selectConfiguration(1);
        }

        // 3. 遍歷所有介面，找出 Printer interface (class=7) & bulk out endpoint
        let foundInterfaceNumber = -1;
        let foundOutEndpointNumber = -1;

        for (const cfg of device.configurations) {
            for (const iface of cfg.interfaces) {
                for (const alt of iface.alternates) {
                    if (alt.interfaceClass === 7) {
                        // 找到 printer interface
                        foundInterfaceNumber = iface.interfaceNumber;
                        // 找 out 端點
                        const outEndpoint = alt.endpoints.find(
                            ep => ep.direction === 'out' && ep.type === 'bulk'
                        );
                        if (outEndpoint) {
                            foundOutEndpointNumber = outEndpoint.endpointNumber;
                            break;
                        }
                    }
                }
                // 若已找到完整資訊，就結束
                if (foundInterfaceNumber !== -1 && foundOutEndpointNumber !== -1) {
                    break;
                }
            }
            if (foundInterfaceNumber !== -1 && foundOutEndpointNumber !== -1) {
                break;
            }
        }

        // 若找不到 printer interface / out endpoint，拋出錯誤
        if (foundInterfaceNumber === -1 || foundOutEndpointNumber === -1) {
            throw new Error('找不到 Printer 介面或對應的 Out 端點 (Class=7, Bulk Out)');
        }

        // 4. Claim 介面 (若不 Claim，之後無法 transfer)
        await device.claimInterface(foundInterfaceNumber);

        return {
            device,
            interfaceNumber: foundInterfaceNumber,
            endpointNumber: foundOutEndpointNumber
        };
    }

    /**
     * 傳送 ESC/POS 指令到指定裝置的 out 端點
     * @param device  已開啟並 claim 的裝置
     * @param endpointNumber  out 端點
     * @param base64ECSPOSCommand   要列印的 ESC/POS 指令字串 (base64-encoded)
     */
    public async printEscPos(
        device: USBDevice,
        endpointNumber: number,
        base64ECSPOSCommand: string
    ): Promise<boolean> {

        // 解碼 Base64 成 Uint8Array
        const commandBuffer = Uint8Array.from(
            atob(base64ECSPOSCommand),
            char => char.charCodeAt(0)
        );

        // 發送到打印機的指定 out 端點
        const result = await device.transferOut(endpointNumber, commandBuffer);
        return (result.status === 'ok');
    }

    /**
     * 用來列出裝置所有可用介面資訊 (除錯或顯示用)
     * @param device USB 裝置
     * @returns DeviceInterfaceInfo 物件
     */
    public getDeviceInterfaces(device: USBDevice): DeviceInterfaceInfo {
        console.log(`device: ${JSONBig.stringify(device)}`);
        if (!device.configurations) {
            throw new Error('裝置無可用設定');
        }
        return {
            productName: device.productName || '未知產品',
            manufacturerName: device.manufacturerName || '未知製造商',
            configurations: device.configurations.map(config => ({
                configurationValue: config.configurationValue,
                interfaces: config.interfaces.map(iface => ({
                    interfaceNumber: iface.interfaceNumber,
                    alternates: iface.alternates.map(alt => ({
                        alternateSetting: alt.alternateSetting,
                        interfaceClass: alt.interfaceClass,
                        interfaceSubclass: alt.interfaceSubclass,
                        interfaceProtocol: alt.interfaceProtocol,
                        endpoints: alt.endpoints.map(endpoint => ({
                            endpointNumber: endpoint.endpointNumber,
                            direction: endpoint.direction,
                            type: endpoint.type
                        }))
                    }))
                }))
            }))
        };
    }

    /**
     * 可以直接要求選擇 USB 裝置，並顯示介面資訊 (用於測試或除錯)
     * @param filters 過濾 USB 裝置 (廠商 ID 等)
     * @returns {DeviceInterfaceInfo}
     */
    public async requestAndShowInterfaces(filters: USBDeviceFilter[] = []): Promise<DeviceInterfaceInfo> {
        this.checkWebUSBSupport();
        try {
            const { device } = await this.requestAndConnectPrinter(filters);
            const info = this.getDeviceInterfaces(device);
            console.log(`interfaces: ${JSONBig.stringify(info)}`);
            return info;
        } catch (error) {
            console.error('取得裝置資訊時發生錯誤:', error);
            throw error;
        }
    }
}

/**
 * PrinterManager 負責對外提供列印流程 (減少外部程式耦合)
 */
export class PrinterManager {
    private printerService: PrinterService;

    /**
     * 依賴注入 (Dependency Injection)
     */
    constructor(printerService: PrinterService) {
        this.printerService = printerService;
    }

    /**
     * 要求使用者選擇裝置，並回傳介面資訊 (只作顯示或除錯)
     */
    public async getDeviceInterfaces(filters: USBDeviceFilter[] = []): Promise<DeviceInterfaceInfo> {
        return this.printerService.requestAndShowInterfaces(filters);
    }

    /**
     * 整合列印流程：
     * 1. 選擇 + 連線 + 找到 Printer Interface & Out 端點
     * 2. 傳送 ESC/POS 指令
     * 3. 列印完 (可選) 關閉裝置
     */
    public async print(commandString: string, filters: USBDeviceFilter[] = []): Promise<void> {
        try {
            // 1. 取得 device 與對應的介面、out 端點
            const { device, endpointNumber } = await this.printerService.requestAndConnectPrinter(filters);

            // 2. 發送列印指令 (ESC/POS)
            const success = await this.printerService.printEscPos(device, endpointNumber, commandString);
            if (!success) {
                throw new Error('列印失敗 (transferOut 非 ok)');
            }

            // 3. 若列印後不再使用，可關閉裝置
            await device.close();
        } catch (error) {
            console.error('Print process error:', error);
            throw error;
        }
    }
}
