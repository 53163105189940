import React, { createContext } from 'react';


export interface IDataAnalysisPageContextType {
    abortController: React.MutableRefObject<AbortController>;
}

export const DataAnalysisPageContext = createContext<IDataAnalysisPageContextType>({} as IDataAnalysisPageContextType)

export const DataAnalysisPageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const abortController = React.useRef(new AbortController());
    return (
        <DataAnalysisPageContext.Provider value={{
            abortController,
        }}>
            {children}
        </DataAnalysisPageContext.Provider>
    );
};