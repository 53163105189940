import { ThirdPartyPlatformServiceProvider } from "@/enums/ThirdPartyPlatformService";
import { IAddBackOfficeMerchantThirdPartyPlatformServiceSettingRequest } from "@/interfaces/Requests/Requests";
import { useAddBackOfficeMerchantThirdPartyPlatformServiceSettingApi } from "@/lib/api/thirdPartyPlatformService";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Flex, Form, Input, InputNumber, Select, Switch, Tabs } from "antd";
import React, { useCallback, useContext, useState } from "react";
import { useMutation } from "react-query";
import ThirdPartyPlatformServiceProviderInputs, { IInputParameter } from "./Inputs/ThirdPartyPlatformServiceProviderInputs";
import InformationPopovers from "./Popovers/InformationPopovers";
import ProviderGuidePopovers from "./Popovers/ProviderGuidePopovers";

const { TabPane } = Tabs;

interface IAddPlatformServiceSettingProps {
    ownRecords: any[] | undefined;
    onRefresh: () => void;
    onClose: () => void;
}

interface IAddPlatformServiceSettingRef {
    refresh: () => void;
}

const AddPlatformServiceSetting = React.forwardRef<IAddPlatformServiceSettingRef, IAddPlatformServiceSettingProps>((props, ref) => {
    const [form] = Form.useForm();
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [selectedProvider, setSelectedProvider] = useState<ThirdPartyPlatformServiceProvider | undefined>(undefined);
    const [activeTab, setActiveTab] = useState<string>('');

    const { mutate, isLoading } = useMutation(useAddBackOfficeMerchantThirdPartyPlatformServiceSettingApi, {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                setSelectedProvider(undefined);
                props.onRefresh();
                props.onClose();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        },
        onError: (error) => {
            messageApi.error(translate('An error occurred. Please try again.'));
            console.error('Error adding platform service setting:', error);
        },
    });

    const handleProviderChange = useCallback((value: ThirdPartyPlatformServiceProvider) => {
        console.log('Provider changed to:', value);
        form.resetFields();
        setSelectedProvider(value);

        // Set activeTab to the first module's name
        const modules = ThirdPartyPlatformServiceProviderInputs[value];
        const firstModuleName = Object.keys(modules)[0];
        setActiveTab(firstModuleName);

        form.setFieldsValue({ provider: value });
    }, [form]);

    const handleFinish = useCallback((values: any) => {
        const { provider, ...restValues } = values;
        const formattedValues: Record<string, string> = {};

        const providerInputs = ThirdPartyPlatformServiceProviderInputs[provider as ThirdPartyPlatformServiceProvider];

        Object.entries(providerInputs).forEach(([moduleName, module]) => {
            module.inputParameters.forEach(input => {
                const key = Object.keys(input)[0];
                if (restValues[key] !== undefined) {
                    formattedValues[`${moduleName}.${key}`] = String(restValues[key]);
                }
            });
        });

        const request: IAddBackOfficeMerchantThirdPartyPlatformServiceSettingRequest = {
            provider: provider as ThirdPartyPlatformServiceProvider,
            values: formattedValues,
        };

        mutate(request);
    }, [mutate]);

    const renderInputField = useCallback((inputField: IInputParameter, moduleName: string) => {
        const key = Object.keys(inputField)[0];
        const type = inputField[key];
        return (
            <Form.Item key={`${moduleName}.${key}`} name={key} label={<>
                <InformationPopovers module={moduleName} parameter={`${key}`}></InformationPopovers>
                {translate(key)}
            </>}>
                {type === 'string' && <Input />}
                {type === 'number' && <InputNumber />}
                {type === 'boolean' && <Switch />}
            </Form.Item>
        );
    }, [translate]);

    const renderModuleTabs = useCallback(() => {
        if (!selectedProvider) return null;

        const modules = ThirdPartyPlatformServiceProviderInputs[selectedProvider];
        return (
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                {Object.entries(modules).map(([moduleName, module]) => (
                    <TabPane tab={translate(moduleName)} key={moduleName}>
                        {selectedProvider && activeTab && (
                            <Flex justify="center">
                                <ProviderGuidePopovers provider={selectedProvider} module={activeTab} />
                            </Flex>)}
                        {module.inputParameters.map(input => renderInputField(input, moduleName))}
                    </TabPane>
                ))}
            </Tabs>
        );
    }, [selectedProvider, activeTab, setActiveTab, translate, renderInputField]);

    return (
        <Form
            form={form}
            onFinish={handleFinish}
            onFinishFailed={(errorInfo) => {
                console.log('Form validation failed:', errorInfo);
            }}
            layout="vertical"
        >
            <Form.Item name="provider" label={translate('Provider')} rules={[{ required: true, message: translate('Provider is required') }]}>
                <Select onChange={handleProviderChange}>
                    {Object.entries(ThirdPartyPlatformServiceProvider).map(([key, value]) => (
                        <Select.Option key={key} value={value}>
                            {translate(value)}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            {renderModuleTabs()}
            <Form.Item>
                <Flex justify="center">
                    <Button type="primary" htmlType="submit" loading={isLoading}>
                        {translate('Save')}
                    </Button>
                </Flex>
            </Form.Item>
        </Form>
    );
});

export default AddPlatformServiceSetting;
