import { DeviceType, MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents } from '@/Templates/enums/templateEnums';
import { LaptopOutlined, MobileOutlined, TabletOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Flex, Row, Select } from 'antd';
import PreviewContainer from './PreviewContainer';
import StyleConfigSelector from './StyleConfigSelector';
import { BasePreviewProps } from './types';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { IMerchantPortalStyleSetting } from '@/Templates/interfaces/templatesInterfaces';
import { useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useGetMerchantPortalStyleSettingsApi } from '@/lib/api/portals';
import { GlobalComponents } from '@/Templates/constants/constants';

interface ComponentPreviewProps extends BasePreviewProps {
    selectedComponentType: MerchantPortalStyleShopComponents;
    setSelectedComponentType: (value: MerchantPortalStyleShopComponents) => void;
    ComponentTemplateComponent: React.ComponentType<any> | null;
    mockComponentProps: any;
    styleState: any;
    handleStyleChange: (type: 'component' | 'page', value: number) => void;
    handleConfigChange: (type: 'component' | 'page', key: string, value: string) => void;
    abortController: React.MutableRefObject<AbortController | null>;
}

const GlobalComponentPreview: React.FC<ComponentPreviewProps> = ({
    containerwidth,
    previewdevicetype,
    windowdevicetype,
    previewContainerRef,
    selectedComponentType,
    setSelectedComponentType,
    ComponentTemplateComponent,
    mockComponentProps,
    styleState,
    handleStyleChange,
    handleConfigChange,
    handlePreviewDeviceChange,
    getPreviewWidth,
    showSeleceTestDataDrawer,
    translate,
    abortController
}) => {
    const { merchantId } = useContext(GlobalContext);
    const [selectedPortalStyleSetting, setSelectedPortalStyleSetting] = useState<IMerchantPortalStyleSetting | undefined>(undefined);
    const merchantPortalStyleSettingsMutation = useMutation("merchantPortalStyleSettingsMutation", async (merchantId: BigInt) => {
        return await useGetMerchantPortalStyleSettingsApi(merchantId);
    });
    useEffect(() => {
        merchantPortalStyleSettingsMutation.mutate(merchantId!);
    }, []);
    useEffect(() => {
        if (merchantPortalStyleSettingsMutation.data?.result) {
            setSelectedPortalStyleSetting(merchantPortalStyleSettingsMutation?.data?.result?.find((setting: IMerchantPortalStyleSetting) =>
                setting.type === MerchantPortalStyleSettingType.Components &&
                setting.name === selectedComponentType.toString()));
        }
    }, [merchantPortalStyleSettingsMutation.data]);
    return (
        <div>
            <Row style={{ marginBottom: 16 }}>
                <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24} style={{ marginBottom: windowdevicetype === DeviceType.Desktop ? 0 : 16 }}>
                    <Flex justify="center" align="center">
                        <Select
                            style={{ width: 200 }}
                            value={selectedComponentType}
                            onChange={(value) => setSelectedComponentType(value)}
                        >
                            {Object.values(GlobalComponents)
                                .map((component) => (
                                    <Select.Option key={component} value={component}>
                                        {translate(component)}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Flex>
                </Col>
                <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24}>
                    <Flex justify="center" align="center">
                        <Button
                            icon={<MobileOutlined />}
                            onClick={() => handlePreviewDeviceChange(DeviceType.Mobile)}
                            type={previewdevicetype === DeviceType.Mobile ? 'primary' : 'default'}
                        >
                            {translate('Mobile')}
                        </Button>
                        <Button
                            icon={<TabletOutlined />}
                            onClick={() => handlePreviewDeviceChange(DeviceType.LapTop)}
                            type={previewdevicetype === DeviceType.LapTop ? 'primary' : 'default'}
                        >
                            {translate('Laptop')}
                        </Button>
                        <Button
                            icon={<LaptopOutlined />}
                            onClick={() => handlePreviewDeviceChange(DeviceType.Desktop)}
                            type={previewdevicetype === DeviceType.Desktop ? 'primary' : 'default'}
                        >
                            {translate('Desktop')}
                        </Button>
                    </Flex>
                </Col>
            </Row>
            <PreviewContainer
                ref={previewContainerRef}
                containerwidth={containerwidth}
                previewwidth={getPreviewWidth()}
                previewdevicetype={previewdevicetype}
                windowdevicetype={windowdevicetype}
            >
                <div>
                    {ComponentTemplateComponent ? (
                        mockComponentProps ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: mockComponentProps.isCentered ? 'center' : 'flex-start',
                                    alignItems: mockComponentProps.isCentered ? 'center' : 'flex-start',
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <ComponentTemplateComponent {...mockComponentProps} />
                            </div>
                        ) : (
                            <Empty description={translate('No props')} />
                        )
                    ) : (
                        <Empty description={translate('No component template')} />
                    )}
                </div>
            </PreviewContainer>

            <Flex justify="center" align="center" style={{ padding: '10px' }} gap="20px">
                {showSeleceTestDataDrawer()}
                <StyleConfigSelector
                    portalStyleSetting={selectedPortalStyleSetting}
                    selectedStyle={styleState.component.style}
                    styleConfig={styleState.component.styleConfig}
                    configFields={styleState.component.configFields}
                    onStyleChange={(value) => handleStyleChange('component', value)}
                    onConfigChange={(key, value) =>
                        handleConfigChange('component', key, value)
                    }
                    styleOptions={[
                        { value: 0, label: translate('Style 1') },
                        { value: 1, label: translate('Style 2') },
                    ]}
                    abortSignal={abortController.current?.signal}
                />
            </Flex>
        </div>
    );
};

export default GlobalComponentPreview;