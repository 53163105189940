import { IOrder } from "@/interfaces/Responses/Responses";
import { IThirdPartyInvoiceSetting } from "@/interfaces/Responses/ThirdPartyInvoice";
import { ThirdPartyValuesContext } from "@/lib/contexts/ThirdPartyValuesContext";
import { Form, Select } from "antd";
import React, { useContext, useImperativeHandle } from "react";
import { IInvoiceParametersTemplateRef } from "../Components/InvoiceSelector";

export interface IECPayInvoiceGroupSelectorProps {
    order: IOrder;
    thirdPartyInvoiceSetting: IThirdPartyInvoiceSetting;
}
export interface IECPayInvoiceGroupSelectorRef extends IInvoiceParametersTemplateRef {

}
const ECPayInvoiceGroupSelector = React.forwardRef((props: IECPayInvoiceGroupSelectorProps, ref: React.ForwardedRef<IECPayInvoiceGroupSelectorRef | undefined>) => {
    const { merchantFieldsForCreate, setMerchantFieldsForCreate } = useContext(ThirdPartyValuesContext);
    const [form] = Form.useForm();
    useImperativeHandle(ref, () => ({
        getMerchantFileds: () => {
            return form.getFieldsValue();
        }
    }));

    return (
        <Form form={form}>
            {
                Object.keys(props.thirdPartyInvoiceSetting.merchantSelectFieldsForCreateOrder).map((key) => {
                    return (
                        <Form.Item
                            name={key}
                            label={key}
                        >
                            <Select>
                                {
                                    props.thirdPartyInvoiceSetting.merchantSelectFieldsForCreateOrder[key].map((value: string) =>
                                        <Select.Option key={value} value={value}>{value}</Select.Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    );
                })
            }
        </Form>
    );
});
export default ECPayInvoiceGroupSelector;