
export enum ThirdPartyPaymentCreateResponseTypeEnum {
    FormPost = "FormPost",
    Redirect = "Redirect",
    QRCode = "QRCode",
    Html = "Html"
}

export enum PaymentMainType {
    /// <summary>
    /// 未分類
    /// </summary>
    None = 'None',
    /// <summary>
    /// （多合一）收銀台
    /// </summary>
    AllInOneIntegration = 'AllInOneIntegration',
    /// <summary>
    /// Offline (超商)
    /// </summary>
    ConvenienceStore = 'ConvenienceStore',
    /// <summary>
    /// ATM 轉帳
    /// </summary>
    ATM = 'ATM',
    /// <summary>
    /// 信用卡
    /// </summary>
    CreditCard = 'CreditCard',
    /// <summary>
    /// 線下轉帳 （所有人工作業皆為此）
    /// 商戶自行處理
    /// </summary>
    MerchantHandle = 'MerchantHandle',
}