import { MerchantPortalStyleShopComponents } from "../enums/templateEnums";

export const GlobalComponents = [
    MerchantPortalStyleShopComponents.NavBar,
    MerchantPortalStyleShopComponents.FullPageLoading,
    MerchantPortalStyleShopComponents.Footer
];

// 定義可用的元件類型
export const AVAILABLE_DYNAMIC_COMPONENTS = (translate: (key: string) => string) => [
    { label: translate('HtmlContentField'), value: 'HtmlContentField', icon: '🎯' },
    { label: translate('Marquee'), value: 'Marquee', icon: '📜' },
];
