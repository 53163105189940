import { ImageUseageType } from '@/enums/Enums';
import { IUpsertPortalStyleRequest } from '@/interfaces/Requests/Merchant';
import { useGetNavBarOptionItemSearchModelApi } from '@/lib/api/frontendsettings';
import { useUploadImageApi } from '@/lib/api/images';
import { useUpsertPortalStyleApi } from '@/lib/api/merchants';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { GenereateHelper } from '@/lib/helpers/GenerateHelper';
import {
    ConfigFieldType,
    DeviceType,
    FontType,
    MerchantPortalStyleSettingType,
    MerchantPortalStyleShopComponents,
    NavBarClickType,
} from '@/Templates/enums/templateEnums';
import { IConfigField, IMerchantPortalStyleSetting, INavBarOption } from '@/Templates/interfaces/templatesInterfaces';
import { CloseCircleOutlined, CloseOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import * as antd from 'antd';
import { Button, Divider, Drawer, Form, FormInstance, Input, InputNumber, Modal, Select, Tree, Upload } from 'antd';
import { Flex } from 'antd/lib';
import JSONBig from 'json-bigint';
import { prop } from 'node_modules/cheerio/dist/esm/api/attributes';
import React, { useContext, useEffect, useState, forwardRef } from 'react';
import InputColor from 'react-input-color';
import { useMutation, UseMutationResult, useQuery } from 'react-query';

export interface StyleConfigSelectorProps {
    title?: string;
    portalStyleSetting: IMerchantPortalStyleSetting | undefined | null;
    selectedStyle: number;
    styleConfig: Record<string, string>;
    configFields: Array<IConfigField>;
    onStyleChange: (value: number) => void;
    onConfigChange: (key: string, value: string) => void;
    styleOptions: Array<{ value: number; label: string }>;
    abortSignal?: AbortSignal;
    hideSaveButton?: boolean;
    ref?: React.RefObject<{
        getFormValues: () => Record<string, string>;
    }>;
}

export enum ItemSearchSubType {
    None = 'None',
    Category = 'Category',
    ItemTag = 'ItemTag',
    Item = 'Item',
}

const StyleConfigSelector = forwardRef<{ getFormValues: () => Record<string, string> }, StyleConfigSelectorProps>(({
    title,
    portalStyleSetting,
    selectedStyle,
    styleConfig,
    configFields,
    onStyleChange,
    onConfigChange,
    styleOptions,
    abortSignal,
    hideSaveButton = false,
}, ref) => {
    const {
        generateHelper,
        deviceType,
        messageApi,
        notificationApi,
        merchantId,
        merchantPortalStyleSettingsMutation,
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [itemSearchSubType, setItemSearchSubType] = useState<ItemSearchSubType>(ItemSearchSubType.Category);
    const UpsertPortalStyleApi = useMutation(
        async (request: IUpsertPortalStyleRequest) => useUpsertPortalStyleApi(request),
        {
            onSuccess: () => {
                merchantPortalStyleSettingsMutation.mutate({
                    merchantId: merchantId!,
                });
                setDrawerVisible(false);
                messageApi.success(translate('Operation successful'));
            },
            onError: () => {
                messageApi.error(translate('Operation failed'));
            },
        }
    );
    const uploadImagesMutation = useMutation<
        string[],
        Error,
        { files: File[]; fieldKey: string; onSuccess?: (urls: string[]) => void }
    >(
        async ({ files, fieldKey, onSuccess }) => {
            const uploadPromises = files.map(async (file) => {
                const formData = new FormData();
                formData.append('UseageType', ImageUseageType.MerchantPortalStyle);
                formData.append('file', file);
                const response = await useUploadImageApi(formData);
                if (response.isSuccess && response.result) {
                    return response.result;
                }
                throw new Error(response.message || translate('Error'));
            });

            const results = await Promise.all(uploadPromises);
            onSuccess?.(results);
            return results;
        },
        {
            onSuccess: (result, { fieldKey, onSuccess }) => {
                if (!onSuccess) {
                    const newUrls = result.map((url: string) => url);
                    const currentUrls = JSON.parse(styleConfig[fieldKey] || '[]');
                    const updatedUrls = [...currentUrls, ...newUrls];
                    onConfigChange(fieldKey, JSON.stringify(updatedUrls));
                    messageApi.success(translate('Image upload successful'));
                }
            },
            onError: (error) => {
                messageApi.error(error.message || translate('Error'));
                console.error('Upload error:', error);
            },
        }
    );
    const handleSave = () => {
        UpsertPortalStyleApi.mutate({
            id: portalStyleSetting?.id ?? BigInt(0),
            type: portalStyleSetting?.type ?? MerchantPortalStyleSettingType.Components,
            name: portalStyleSetting?.name ?? '',
            style: selectedStyle,
            styleSetting: styleConfig,
            pagePath: '',
            dynamicComponents: [],
        });
    };

    const handleDefault = () => {
        Modal.confirm({
            title: translate('Confirm'),
            content: translate(
                'Are you sure you want to use default settings? This will overwrite current configurations.'
            ),
            onOk: () => {
                configFields.forEach((field) => {
                    if (field.defaultValue) {
                        onConfigChange(field.key, field.defaultValue);
                    }
                });
                onStyleChange(styleOptions[0].value);
                messageApi.success(translate('Default settings applied'));
            },
            onCancel: () => {
                console.log('Cancelled using default settings');
            },
        });
    };

    const [navBarOptions, setNavBarOptions] = useState<INavBarOption[]>([]);

    useEffect(() => {
        if (portalStyleSetting?.type === MerchantPortalStyleSettingType.Components && portalStyleSetting?.name === MerchantPortalStyleShopComponents.NavBar) {
            try {
                const parsedOptions = JSONBig.parse(styleConfig.navBarOptions || '[]');
                setNavBarOptions(parsedOptions);
            } catch (error) {
                console.error('解析 NavBarOptions 時出錯:', error);
                setNavBarOptions([]);
            }
        }
    }, [portalStyleSetting?.type, portalStyleSetting?.name, styleConfig]);

    // Add method to get form values
    const getFormValues = () => {
        return styleConfig;
    };

    // Expose the method via ref
    React.useImperativeHandle(ref, () => ({
        getFormValues
    }));

    return (
        <Flex justify="space-between">
            <Button type="default" onClick={() => setDrawerVisible(true)}>
                {translate('Configure')}
            </Button>
            <Drawer
                title={title ?? translate('Style Configuration')}
                placement={'right'}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                width={deviceType === DeviceType.Mobile ? '100%' : undefined}
            >
                <Form layout="vertical">
                    <Form.Item key="style-selector" label={translate('Config.Style')}>
                        <Select value={selectedStyle} onChange={onStyleChange} options={styleOptions} />
                    </Form.Item>
                    <Divider />
                    {configFields.map((field, index) => (
                        <React.Fragment key={`config-field-${field.label}`}>
                            {index > 0 && <Divider />}
                            <Form.Item label={
                                <>
                                    {translate(field.label)}
                                    {field.description && (
                                        <span style={{
                                            marginLeft: 8,
                                            fontSize: '12px',
                                            color: 'rgba(0, 0, 0, 0.45)',
                                            fontWeight: 'normal'
                                        }}>
                                            ({translate(field.description)})
                                        </span>
                                    )}
                                </>
                            }>
                                <RecursiveConfigDrawer
                                    field={field}
                                    styleConfig={styleConfig}
                                    onConfigChange={onConfigChange}
                                    translate={translate}
                                    uploadImagesMutation={uploadImagesMutation}
                                    merchantId={merchantId!}
                                    abortSignal={abortSignal}
                                    level={1}
                                    generateHelper={generateHelper}
                                    itemSearchSubType={itemSearchSubType}
                                    setItemSearchSubType={setItemSearchSubType}
                                    navBarOptions={navBarOptions}
                                    setNavBarOptions={setNavBarOptions}
                                />
                            </Form.Item>
                        </React.Fragment>
                    ))}
                </Form>
                <Divider />
                {
                    !hideSaveButton &&
                    <Flex justify="space-between">
                        <Button shape="round" type="default" onClick={handleDefault}>
                            {translate('Use Default')}
                        </Button>
                        <Button
                            shape="round"
                            type="primary"
                            loading={UpsertPortalStyleApi.isLoading}
                            onClick={handleSave}
                        >
                            {translate('Save')}
                        </Button>
                    </Flex>
                }
            </Drawer >
        </Flex>
    );
});

// Add display name for the forwarded ref component
StyleConfigSelector.displayName = 'StyleConfigSelector';

interface RecursiveConfigDrawerProps {
    field: IConfigField;
    styleConfig: Record<string, string>;
    onConfigChange: (key: string, value: string) => void;
    translate: (key: string) => string;
    uploadImagesMutation: UseMutationResult<string[], Error, { files: File[]; fieldKey: string; onSuccess?: (urls: string[]) => void }>;
    merchantId: BigInt;
    abortSignal?: AbortSignal;
    level: number;
    generateHelper: GenereateHelper;
    itemSearchSubType?: ItemSearchSubType;
    setItemSearchSubType: (value: ItemSearchSubType) => void;
    navBarOptions: INavBarOption[];
    setNavBarOptions: React.Dispatch<React.SetStateAction<INavBarOption[]>>;
}

interface INavBarOptionFormValues {
    name: string;
    type: NavBarClickType;
    redirectPage?: string;
    itemSearchSubType?: ItemSearchSubType;
    categoryIds?: string[];
    itemIds?: string[];
    itemTagIds?: string[];
    detailItem?: string;
}

const useNavBarOptionForm = (
    form: FormInstance<any>,
    editingOption: INavBarOption | null,
    onUpdateOption: (updatedOption: INavBarOption) => void,
    onClose: () => void,
    setItemSearchSubType: (type: ItemSearchSubType) => void
) => {
    useEffect(() => {
        if (editingOption) {
            // 先設置 type 和 itemSearchSubType
            const initialValues: INavBarOptionFormValues = {
                name: editingOption.name,
                type: editingOption.type,
                itemSearchSubType: editingOption.itemSearchSubType || ItemSearchSubType.ItemTag,
            };

            // 設置基本值
            form.setFieldsValue(initialValues);

            // 強制更新 itemSearchSubType
            if (editingOption.type === NavBarClickType.ItemSearch) {
                setItemSearchSubType(editingOption.itemSearchSubType || ItemSearchSubType.ItemTag);
            }

            // 延遲設置其他值，確保子表單已經渲染
            setTimeout(() => {
                form.setFieldsValue({
                    redirectPage: editingOption.path,
                    categoryIds: editingOption.categoryIds?.map(id => id.toString()),
                    itemIds: editingOption.itemIds?.map(id => id.toString()),
                    itemTagIds: editingOption.itemTagIds?.map(id => id.toString()),
                    detailItem: editingOption.type === NavBarClickType.RedirectItemDetailPage
                        ? editingOption.itemIds?.[0]?.toString()
                        : undefined
                });
            }, 100);
        }
    }, [editingOption]);

    const handleSubmit = async () => {
        if (!editingOption) return;

        try {
            const values = await form.validateFields();
            const updatedOption: INavBarOption = {
                ...editingOption,
                name: values.name,
                type: values.type,
                path: values.type === NavBarClickType.RedirectPage ? values.redirectPage : undefined,
                itemSearchSubType: values.type === NavBarClickType.ItemSearch ? values.itemSearchSubType : undefined,
                categoryIds: values.type === NavBarClickType.ItemSearch ? values.categoryIds : undefined,
                itemIds: values.type === NavBarClickType.ItemSearch ? values.itemIds :
                    values.type === NavBarClickType.RedirectItemDetailPage ? [values.detailItem] : undefined,
                itemTagIds: values.type === NavBarClickType.ItemSearch ? values.itemTagIds : undefined,
            };
            onUpdateOption(updatedOption);
        } catch (error) {
            console.error('表單驗證失敗:', error);
        }
    };

    const handleClose = () => {
        form.resetFields();
        onClose();
    };

    return {
        handleSubmit,
        handleClose
    };
};

// 添加樹節點介面定義
interface TreeNodeType {
    key: string;
    title: React.ReactNode;
    children?: TreeNodeType[];
    isLeaf?: boolean;
    selectable?: boolean;
}

const RecursiveConfigDrawer: React.FC<RecursiveConfigDrawerProps> = ({
    field,
    styleConfig,
    onConfigChange,
    translate,
    uploadImagesMutation,
    merchantId,
    abortSignal,
    level,
    generateHelper,
    itemSearchSubType,
    setItemSearchSubType,
    navBarOptions,
    setNavBarOptions,
}) => {
    const { notificationApi } = useContext(GlobalContext);
    const [form] = Form.useForm();
    const [childDrawerVisible, setChildDrawerVisible] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [editingOptionId, setEditingOptionId] = useState<BigInt | null>(null);
    const [editingOption, setEditingOption] = useState<INavBarOption | null>(null);
    const [, forceUpdate] = useState({});

    const { data: navBarOptionItemSearchModel, isLoading: isNavBarOptionItemSearchModelLoading } = useQuery(
        ['navBarOptionItemSearchModel', merchantId],
        () => {
            return useGetNavBarOptionItemSearchModelApi(abortSignal);
        },
        { enabled: !!merchantId }
    );

    const { handleSubmit, handleClose } = useNavBarOptionForm(
        form,
        editingOption,
        (updatedOption) => {
            updateNavBarOption(updatedOption);
            setEditingOption(null);
        },
        () => {
            setEditingOption(null);
        },
        setItemSearchSubType
    );

    useEffect(() => {
        if (field.type === ConfigFieldType.NavBarOption) {
            setNavBarOptions(navBarOptions);
            const allKeys = getAllNodeKeys(navBarOptions);
            setExpandedKeys(allKeys);
        }
    }, [field, navBarOptions]);

    const addNavBarOption = (parentOption?: INavBarOption): void => {
        const newOption: INavBarOption = {
            id: generateHelper.getSnowflakeIdBigInt(),
            merchantId: merchantId,
            name: translate('New Option'),
            type: NavBarClickType.RedirectPage,
            path: '/',
            isDeleted: false,
            options: [],
        };

        if (parentOption) {
            const updatedOptions = updateOption(navBarOptions, parentOption.id, (option) => {
                const newOptions = option.options ? [...option.options, newOption] : [newOption];
                return { ...option, options: newOptions };
            });
            setNavBarOptions(updatedOptions);
            onConfigChange(field.key, JSONBig.stringify(updatedOptions));
        } else {
            const updatedOptions = [...navBarOptions, newOption];
            setNavBarOptions(updatedOptions);
            onConfigChange(field.key, JSONBig.stringify(updatedOptions));
        }
    };

    const updateOption = (
        options: INavBarOption[],
        targetId: BigInt,
        updater: (option: INavBarOption) => INavBarOption
    ): INavBarOption[] => {
        return options.map((option) => {
            if (option.id.toString() === targetId.toString()) {
                return updater(option);
            } else if (option.options && option.options.length > 0) {
                return { ...option, options: updateOption(option.options, targetId, updater) };
            } else {
                return option;
            }
        });
    };

    const updateNavBarOption = (updatedOption: INavBarOption) => {
        const updatedOptions = updateOption(navBarOptions, updatedOption.id, () => updatedOption);
        setNavBarOptions(updatedOptions);
        onConfigChange(field.key, JSONBig.stringify(updatedOptions));
    };

    const removeNavBarOption = (optionId: BigInt) => {
        Modal.confirm({
            title: translate('Confirm'),
            content: `${translate('Are you sure you want to delete this option')}?`,
            onOk: () => {
                const removeOption = (options: INavBarOption[], targetId: BigInt): INavBarOption[] => {
                    return options
                        .filter((option) => option.id.toString() !== targetId.toString())
                        .map((option) => ({
                            ...option,
                            options: option.options ? removeOption(option.options, targetId) : [],
                        }));
                };

                const updatedOptions = removeOption(navBarOptions, optionId);
                setNavBarOptions(updatedOptions);
                onConfigChange(field.key, JSONBig.stringify(updatedOptions));
            },
        });
    };

    const openEditModal = (option: INavBarOption) => {
        setEditingOption(option);
        form.setFieldsValue({
            name: option.name,
            type: option.type,
            redirectPage: option.path,
            categoryIds: option.categoryIds,
            itemIds: option.itemIds,
            itemTagIds: option.itemTagIds,
            itemSearchSubType: option.itemSearchSubType,
            detailItem: option.type === NavBarClickType.RedirectItemDetailPage ? option.itemIds?.[0] : undefined,
        });
    };

    const renderNavBarOptionNode = (option: INavBarOption, level: number) => {
        const isEditing = option.id.toString() === (editingOptionId ? editingOptionId.toString() : '');
        const index = option.name.toLowerCase().indexOf(searchValue.toLowerCase());
        const beforeStr = option.name.substring(0, index);
        const matchedStr = option.name.substring(index, index + searchValue.length);
        const afterStr = option.name.substring(index + searchValue.length);

        const nameTitle =
            index > -1 ? (
                <span>
                    {beforeStr}
                    <span style={{ color: 'red' }}>{matchedStr}</span>
                    {afterStr}
                </span>
            ) : (
                <span>{option.name}</span>
            );

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    {isEditing ? (
                        <Input
                            value={option.name}
                            onChange={(e) => updateNavBarOption({ ...option, name: e.target.value })}
                            onBlur={() => setEditingOptionId(null)}
                            onPressEnter={() => setEditingOptionId(null)}
                            autoFocus
                        />
                    ) : (
                        <span onDoubleClick={() => setEditingOptionId(option.id)}>{nameTitle}</span>
                    )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
                    <EditOutlined
                        style={{ color: 'blue', cursor: 'pointer', marginRight: 8 }}
                        onClick={() => openEditModal(option)}
                    />
                    <CloseCircleOutlined
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => removeNavBarOption(option.id)}
                    />
                </div>
            </div>
        );
    };

    const navBarOptionsToTreeData = (
        options: INavBarOption[],
        searchValue: string,
        level: number = 1
    ): TreeNodeType[] => {
        const nodes = options
            .map((option) => {
                const index = option.name.toLowerCase().indexOf(searchValue.toLowerCase());
                const hasSearchMatch = index > -1;
                const children = option.options
                    ? navBarOptionsToTreeData(option.options, searchValue, level + 1)
                    : [];

                if (hasSearchMatch || children.length > 0) {
                    return {
                        key: option.id.toString(),
                        title: renderNavBarOptionNode(option, level),
                        children: [
                            ...children,
                            {
                                key: `add_sub_option_${option.id}`,
                                title: (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span
                                            style={{ color: 'green', cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                addNavBarOption(option);
                                            }}
                                        >
                                            <PlusOutlined /> {translate('Add')}
                                        </span>
                                    </div>
                                ),
                                isLeaf: true,
                                selectable: false
                            },
                        ],
                    };
                } else {
                    return null;
                }
            })
            .filter((item) => item !== null) as TreeNodeType[];

        // 根層級的"新增"節點
        if (level === 1) {
            nodes.push({
                key: 'add_root_option',
                title: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                            style={{ color: 'green', cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                addNavBarOption();
                            }}
                        >
                            <PlusOutlined /> {translate('Add')}
                        </span>
                    </div>
                ),
                isLeaf: true,
                selectable: false
            });
        }

        return nodes;
    };

    const [dataList, setDataList] = useState<{ key: string; name: string }[]>([]);

    const generateList = (options: INavBarOption[]) => {
        const list: { key: string; name: string }[] = [];
        const traverse = (opts: INavBarOption[]) => {
            opts.forEach((option) => {
                const key = option.id.toString();
                list.push({ key, name: option.name });
                if (option.options && option.options.length > 0) {
                    traverse(option.options);
                }
            });
        };
        traverse(options);
        setDataList(list);
    };

    useEffect(() => {
        generateList(navBarOptions);
    }, [navBarOptions]);

    const getParentKey = (key: string, tree: INavBarOption[]): string | undefined => {
        let parentKey: string | undefined;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.options) {
                if (node.options.some((item) => item.id.toString() === key)) {
                    parentKey = node.id.toString();
                } else if (getParentKey(key, node.options)) {
                    parentKey = getParentKey(key, node.options);
                }
            }
        }
        return parentKey;
    };

    const getAllNodeKeys = (options: INavBarOption[]): string[] => {
        let keys: string[] = [];
        options.forEach((option) => {
            keys.push(option.id.toString());
            if (option.options && option.options.length > 0) {
                keys = keys.concat(getAllNodeKeys(option.options));
            }
        });
        return keys;
    };

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value);
        const expandedKeys = dataList
            .map((item) => {
                if (item.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                    return getParentKey(item.key, navBarOptions);
                }
                return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i) as string[];
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(true);
    };

    const renderConfigField = (field: IConfigField) => {
        switch (field.type) {
            case ConfigFieldType.SubMenu:
                return (
                    <>
                        <Button onClick={() => setChildDrawerVisible(true)}>
                            {translate(field.label)}
                        </Button>
                        <Drawer
                            title={translate(field.label)}
                            placement="right"
                            onClose={() => setChildDrawerVisible(false)}
                            open={childDrawerVisible}
                        >
                            <Form
                                form={form}
                                layout="vertical"
                            >
                                {field.configFields?.map((childField) => (
                                    <Form.Item
                                        key={`config-field-${childField.label}`}
                                        label={translate(childField.label)}
                                    >
                                        <RecursiveConfigDrawer
                                            field={childField}
                                            styleConfig={styleConfig}
                                            onConfigChange={onConfigChange}
                                            translate={translate}
                                            uploadImagesMutation={uploadImagesMutation}
                                            merchantId={merchantId}
                                            abortSignal={abortSignal}
                                            level={level + 1}
                                            generateHelper={generateHelper}
                                            itemSearchSubType={itemSearchSubType}
                                            setItemSearchSubType={setItemSearchSubType}
                                            navBarOptions={navBarOptions}
                                            setNavBarOptions={setNavBarOptions}
                                        />
                                    </Form.Item>
                                ))}
                            </Form>
                        </Drawer>
                    </>
                );
            case ConfigFieldType.NavBarOption:
                return (
                    <>
                        <Input
                            placeholder={translate('Search')}
                            onChange={onSearchChange}
                            style={{ marginBottom: 8 }}
                        />
                        <Tree
                            className="draggable-tree"
                            showLine={{ showLeafIcon: false }}
                            expandedKeys={expandedKeys}
                            onExpand={(keys) => {
                                setExpandedKeys(keys as string[]);
                                setAutoExpandParent(false);
                            }}
                            treeData={navBarOptionsToTreeData(navBarOptions, searchValue)}
                            autoExpandParent={autoExpandParent}
                            draggable={{ icon: false }}
                            onDrop={onDrop}
                        />
                    </>
                );
            case ConfigFieldType.Color:
                return (
                    <InputColor
                        initialValue={styleConfig[field.key] || field.defaultValue || '#ffffff'}
                        onChange={(e) => onConfigChange(field.key, e.hex)}
                    />
                );
            case ConfigFieldType.Select:
                return (
                    <Select
                        value={styleConfig[field.key] || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={field.options?.map((option) => ({
                            label: translate(option.label),
                            value: option.value,
                        }))}
                    />
                );
            case ConfigFieldType.Number:
                return (
                    <InputNumber
                        value={styleConfig[field.key] ? Number(styleConfig[field.key]) : undefined}
                        onChange={(value) => onConfigChange(field.key, value?.toString() || '')}
                        min={field.min}
                        max={field.max}
                    />
                );
            case ConfigFieldType.Input:
                return (
                    <Input
                        value={styleConfig[field.key] || ''}
                        onChange={(e) => onConfigChange(field.key, e.target.value)}
                    />
                );
            case ConfigFieldType.Upload:
                const fileList = styleConfig[field.key] ? JSON.parse(styleConfig[field.key]) : [];
                return (
                    <Upload
                        fileList={fileList.map((url: string) => ({
                            uid: url,
                            name: url.split('/').pop(),
                            status: 'done',
                            url: url,
                        }))}
                        accept={field.acceptTypes ? field.acceptTypes.join(',') : 'image/*'}
                        showUploadList={true}
                        maxCount={field.maxUploadCount || 5}
                        multiple={field.maxUploadCount ? field.maxUploadCount > 1 : true}
                        onRemove={(file) => {
                            const newFileList = fileList.filter((item: string) => item !== file.url);
                            onConfigChange(field.key, JSON.stringify(newFileList));
                        }}
                        beforeUpload={(file, fileList) => {
                            uploadImagesMutation.mutate({ files: fileList, fieldKey: field.key });
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />} loading={uploadImagesMutation.isLoading}>
                            {fileList.length > 0 ? translate('Add More') : translate('Upload')}
                        </Button>
                    </Upload>
                );
            case ConfigFieldType.Pages:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={navBarOptionItemSearchModel?.result?.categories.map((page) => ({
                            label: typeof page.name === 'string' ? translate(page.name) : '',
                            value: page.id?.toString() || '',
                        }))}
                        loading={isNavBarOptionItemSearchModelLoading}
                    />
                );
            case ConfigFieldType.ItemTags:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={navBarOptionItemSearchModel?.result?.itemTags.map((tag) => ({
                            label: typeof tag.name === 'string' ? translate(tag.name) : '',
                            value: tag.id?.toString() || '',
                        }))}
                        loading={isNavBarOptionItemSearchModelLoading}
                    />
                );
            case ConfigFieldType.Categories:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={navBarOptionItemSearchModel?.result?.categories.map((category) => ({
                            label: typeof category.name === 'string' ? translate(category.name) : '',
                            value: category.id?.toString() || '',
                        }))}
                        loading={isNavBarOptionItemSearchModelLoading}
                    />
                );
            case ConfigFieldType.Items:
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue || ''}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={navBarOptionItemSearchModel?.result?.items?.map((item) => ({
                            label: typeof item.name === 'string' ? translate(item.name) : '',
                            value: item.id?.toString() || '',
                        }))}
                        loading={isNavBarOptionItemSearchModelLoading}
                    />
                );
            case ConfigFieldType.UploadMediaWithLink:
                const [localMediaValues, setLocalMediaValues] = useState(() => {
                    try {
                        if (typeof styleConfig[field.key] === 'string') {
                            const parsed = JSON.parse(styleConfig[field.key]);
                            if (Array.isArray(parsed) && parsed.every(item =>
                                typeof item === 'object' &&
                                'mediaUrl' in item &&
                                'link' in item
                            )) {
                                return parsed;
                            }
                        }
                        return [{ mediaUrl: '', link: '' }];
                    } catch (error) {
                        console.error('解析 mediaValues 失敗:', error);
                        return [{ mediaUrl: '', link: '' }];
                    }
                });

                useEffect(() => {
                    try {
                        if (typeof styleConfig[field.key] === 'string') {
                            const parsed = JSON.parse(styleConfig[field.key]);
                            if (Array.isArray(parsed) && parsed.every(item =>
                                typeof item === 'object' &&
                                'mediaUrl' in item &&
                                'link' in item
                            )) {
                                setLocalMediaValues(parsed);
                            }
                        }
                    } catch (error) {
                        console.error('監聽 styleConfig 更新失敗:', error);
                    }
                }, [styleConfig[field.key]]);

                const updateMediaValues = (newValues: Array<{ mediaUrl: string, link: string }>) => {
                    setLocalMediaValues(newValues);
                    onConfigChange(field.key, JSON.stringify(newValues));
                };

                return (
                    <div>
                        <Form.List
                            name={field.key}
                            initialValue={localMediaValues}
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name }, index) => (
                                        <div
                                            key={key}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px',
                                                marginBottom: '16px',
                                                padding: '16px',
                                                border: '1px dashed #d9d9d9',
                                                borderRadius: '8px',
                                                backgroundColor: '#fafafa'
                                            }}
                                        >
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: '1px solid #f0f0f0',
                                                paddingBottom: '8px',
                                                marginBottom: '8px'
                                            }}>
                                                <antd.Typography.Text strong>
                                                    {translate('Media Item')} {index + 1}
                                                </antd.Typography.Text>
                                                <Button
                                                    type="text"
                                                    danger
                                                    icon={<CloseOutlined />}
                                                    onClick={() => {
                                                        remove(name);
                                                        const newValues = [...localMediaValues];
                                                        newValues.splice(index, 1);
                                                        updateMediaValues(newValues);
                                                    }}
                                                />
                                            </div>
                                            <Upload
                                                fileList={localMediaValues[index]?.mediaUrl ? [{
                                                    uid: localMediaValues[index].mediaUrl,
                                                    name: localMediaValues[index].mediaUrl.split('/').pop(),
                                                    status: 'done',
                                                    url: localMediaValues[index].mediaUrl,
                                                }] : []}
                                                accept={field.acceptTypes ? field.acceptTypes.join(',') : 'image/*'}
                                                showUploadList={true}
                                                maxCount={1}
                                                multiple={false}
                                                onRemove={() => {
                                                    const newValues = [...localMediaValues];
                                                    newValues[index] = {
                                                        ...newValues[index],
                                                        mediaUrl: ''
                                                    };
                                                    updateMediaValues(newValues);
                                                }}
                                                beforeUpload={(file) => {
                                                    uploadImagesMutation.mutate({
                                                        files: [file],
                                                        fieldKey: field.key,
                                                        onSuccess: (urls) => {
                                                            const newValues = [...localMediaValues];
                                                            newValues[index] = {
                                                                ...newValues[index],
                                                                mediaUrl: urls[0]
                                                            };
                                                            updateMediaValues(newValues);
                                                        }
                                                    });
                                                    return false;
                                                }}
                                            >
                                                <Button
                                                    icon={<UploadOutlined />}
                                                    loading={uploadImagesMutation.isLoading}
                                                    disabled={localMediaValues[index]?.mediaUrl !== ''}
                                                >
                                                    {localMediaValues[index]?.mediaUrl ? translate('Change') : translate('Upload')}
                                                </Button>
                                            </Upload>
                                            <Input
                                                placeholder={translate('Enter link URL')}
                                                value={localMediaValues[index]?.link}
                                                onChange={(e) => {
                                                    const newValues = [...localMediaValues];
                                                    newValues[index] = {
                                                        ...newValues[index],
                                                        link: e.target.value
                                                    };
                                                    updateMediaValues(newValues);
                                                }}
                                            />
                                            {localMediaValues[index]?.mediaUrl && (
                                                <div style={{ marginTop: '8px', textAlign: 'center' }}>
                                                    <div style={{ marginBottom: '8px', color: '#666' }}>
                                                        {translate('Preview')}
                                                    </div>
                                                    <antd.Image
                                                        src={localMediaValues[index].mediaUrl}
                                                        alt="preview"
                                                        style={{ maxWidth: '100%', height: 'auto' }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                // 不該超過 max 
                                                if (field.max ? localMediaValues.length >= field.max : false) {
                                                    notificationApi.error({
                                                        message: translate('Max count reached'),
                                                    });
                                                    return;
                                                }
                                                add({ mediaUrl: '', link: '' });
                                                updateMediaValues([...localMediaValues, { mediaUrl: '', link: '' }]);
                                            }}
                                            icon={<PlusOutlined />}
                                            style={{ width: '100%' }}
                                        >
                                            {translate('Add')}
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>
                );
            case ConfigFieldType.Font:
                return (
                    <Form.Item
                        key={field.key}
                        tooltip={field.description && translate(field.description)}
                    >
                        <Select
                            value={styleConfig[field.key] || FontType.Default}
                            onChange={(value) => onConfigChange(field.key, value)}
                            style={{ width: '100%' }}
                        >
                            {Object.values(FontType).map((font) => (
                                <Select.Option key={font} value={font}>
                                    {translate(`Font.${font}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                );
            case ConfigFieldType.Html:
                return (
                    <antd.Input.TextArea
                        value={styleConfig[field.key] || ''}
                        onChange={(e) => onConfigChange(field.key, e.target.value)}
                        placeholder={translate('Enter HTML content')}
                        autoSize={{ minRows: 6 }}
                    />
                );
            default:
                return null;
        }
    };

    const renderItemSearchSubType = (itemSearchSubType: ItemSearchSubType | undefined) => {
        console.log('itemSearchSubType', itemSearchSubType);
        switch (itemSearchSubType) {
            case ItemSearchSubType.Category:
                return <Form.Item name={'categoryIds'} label={translate('Category')}>
                    <Select mode='multiple'>
                        {navBarOptionItemSearchModel?.result?.categories?.map((category) => (
                            <Select.Option key={category.id.toString()} value={category.id.toString()}>
                                {category.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            case ItemSearchSubType.Item:
                return <Form.Item name={'item'} label={translate('Item')}>
                    <Select mode='multiple'>
                        {navBarOptionItemSearchModel?.result?.items?.map((item) => (
                            <Select.Option key={item.id.toString()} value={item.id.toString()}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            case ItemSearchSubType.ItemTag:
                return <Form.Item name={'itemTagIds'} label={translate('Tag')}>
                    <Select mode='multiple'>
                        {navBarOptionItemSearchModel?.result?.itemTags?.map((itemTag) => (
                            <Select.Option key={itemTag.id.toString()} value={itemTag.id.toString()}>
                                {itemTag.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
        }
    }

    const onDrop = (info: any) => {
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

        // 忽特殊節點的拖曳
        if (dragKey.startsWith('add_') || dropKey.startsWith('add_')) {
            return;
        }

        const loop = (
            data: INavBarOption[],
            key: string,
            callback: (item: INavBarOption, index: number, arr: INavBarOption[]) => void
        ): boolean => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].id.toString() === key) {
                    callback(data[i], i, data);
                    return true;
                }
                if (data[i].options) {
                    if (loop(data[i].options, key, callback)) {
                        return true;
                    }
                }
            }
            return false;
        };

        const data = [...navBarOptions];
        let dragObj: INavBarOption | undefined;

        // 找到被拖動的節點並移除
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        if (!dragObj) {
            return;
        }

        if (!info.dropToGap) {
            // 放在節點上 = 成為子節點
            loop(data, dropKey, (item) => {
                item.options = item.options || [];
                item.options.unshift(dragObj!);
            });
        } else {
            let ar: INavBarOption[] = data;
            let i: number = 0;
            let found = false;

            // 尋找目標位置
            if (dropKey !== 'add_root_option') {
                found = loop(data, dropKey, (_item, index, arr) => {
                    ar = arr;
                    i = index;
                });
            } else {
                // 處理根節點的情況
                i = data.length;
            }

            // 根據 dropPosition 插入點
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        }

        setNavBarOptions(data);
        onConfigChange(field.key, JSONBig.stringify(data));
    };

    return (
        <>
            {renderConfigField(field)}
            <Modal
                open={editingOption !== null}
                title={translate('Edit Option')}
                onOk={handleSubmit}
                onCancel={handleClose}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        type: NavBarClickType.None,
                        itemSearchSubType: ItemSearchSubType.Category
                    }}
                >
                    <Form.Item name="name" label={translate('DisplayName')}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="type" label={translate('Click type')}>
                        <Select style={{ width: '100%' }}>
                            {Object.values(NavBarClickType).map((type) => (
                                <Select.Option key={type} value={type}>
                                    {translate(type)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {form.getFieldValue('type') === NavBarClickType.RedirectPage && (
                        <Form.Item name="redirectPage" label={translate('Redirect page')}>
                            <Select>
                                {navBarOptionItemSearchModel?.result?.pageViewModels?.map((page, index) => (
                                    <Select.Option key={index.toString()} value={page.path.toString()}>
                                        {translate(page.title)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    {form.getFieldValue('type') === NavBarClickType.ItemSearch && (
                        <>
                            <Form.Item name="itemSearchSubType" label={translate('SubType')}>
                                <Select onChange={(value) => setItemSearchSubType(value)}>
                                    {Object.values(ItemSearchSubType).map((type) => (
                                        <Select.Option key={type} value={type}>
                                            {translate(type)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {renderItemSearchSubType(form.getFieldValue('itemSearchSubType'))}
                        </>
                    )}
                    {form.getFieldValue('type') === NavBarClickType.RedirectItemDetailPage && (
                        <Form.Item name="detailItem" label={translate('Item')}>
                            <Select>
                                {navBarOptionItemSearchModel?.result?.items?.map((item) => (
                                    <Select.Option key={item.id.toString()} value={item.id.toString()}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default StyleConfigSelector;
