import { IAlterMerchantThirdPartyInvoiceSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyInvoiceSettingViewModel } from "@/interfaces/Responses/Responses";
import { IThirdPartyInvoiceSetting } from "@/interfaces/Responses/ThirdPartyInvoice";
import { useAlterMerchantThirdPartyInvoiceSettingApi, useGetThirdPartyInvoiceSettingApi } from "@/lib/api/thirdPartyInvoices";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Col, Form, Input, Row } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation } from "react-query";

export interface IEditInvoiceProps {
    onRefresh: () => void;
    onClose: () => void;
}
export interface IEditInvoiceRef {
    onOpen: (record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel) => void;
    onClose: () => void;
}

const EditInvoice = React.forwardRef((props: IEditInvoiceProps, ref: React.ForwardedRef<IEditInvoiceRef | undefined>) => {
    const [form] = Form.useForm();
    const [vm, setVm] = useState<IBackOfficeMerchantThirdPartyInvoiceSettingViewModel | undefined>(undefined);
    const [isFormReady, setIsFormReady] = useState(false);
    const { merchantId } = useContext(GlobalContext);
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [merchantSettings, setMerchantSettings] = useState<Record<string, string> | undefined>(vm?.merchantSettings);
    const { data: tpisFromApi, mutate } = useMutation(async (settingId: BigInt) => await useGetThirdPartyInvoiceSettingApi(settingId), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setTpis(response.result);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { data: alterRespone, mutate: alterMutate, isLoading } = useMutation(async (request: IAlterMerchantThirdPartyInvoiceSettingRequest) => await useAlterMerchantThirdPartyInvoiceSettingApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    props.onRefresh();
                    props.onClose();
                }
                else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );
    const [isInitDone, setIsInitDone] = useState(false);
    const [tpis, setTpis] = useState<IThirdPartyInvoiceSetting | undefined>(undefined);
    const reset = () => {
        setTpis(undefined);
        setIsInitDone(false);
    }

    useImperativeHandle(ref, () => ({
        onOpen: (record: IBackOfficeMerchantThirdPartyInvoiceSettingViewModel) => {
            if (record) {
                mutate(record.thirdPartySettingId);
                setVm(record);
            }
        },
        onClose: () => {
            reset();
        }
    }));

    useEffect(() => {
        if (tpis) {
            const initialMerchantSettings = tpis.merchantFillFieldsForBasicInfo.reduce((acc: Record<string, any>, field) => {
                acc[field] = vm?.merchantSettings?.[field] || '';
                return acc;
            }, {});
            setMerchantSettings(initialMerchantSettings);
            setIsInitDone(true);
        }
    }, [tpis]);

    const handleSubmit = () => {
        if (!merchantId || !vm || !merchantSettings)
            return;
        var request: IAlterMerchantThirdPartyInvoiceSettingRequest = {
            settingId: vm.thirdPartySettingId,
            setting: merchantSettings,
            isDisabled: vm.isDisable,
        }
        alterMutate(request);
    };

    if (!tpis)
        return <></>;

    return (
        merchantSettings && isInitDone &&
        <>
            <Row justify={'center'}>
                <Title level={4}>
                    {translate(`${tpis.providerName}`)} {translate('Parameters')}
                </Title>
            </Row>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                {Object.keys(merchantSettings).map((key) => (
                    <Form.Item
                        key={key}
                        name={key}
                        label={translate(key)}
                    >
                        <Row justify="space-between">
                            <Col span={20}>
                                <Input
                                    defaultValue={merchantSettings[key]} onChange={(e) => setMerchantSettings({ ...merchantSettings, [key]: e.target.value })}
                                />
                            </Col>
                            {/* <Col span={4}>
                            <Button onClick={() => handleRemoveField(key)}>
                                Remove
                            </Button>
                        </Col> */}
                        </Row>
                    </Form.Item>
                ))}
                {/* <Form.Item>
                <Button type="dashed" onClick={handleAddField} block icon={<PlusOutlined />}>
                    {translate('Add Field')}
                </Button>
            </Form.Item> */}
                <Row justify={'center'}>
                    <Form.Item>
                        <Button
                            type="primary"
                            loading={isLoading}
                            onClick={() => { handleSubmit() }}>
                            {translate('Save')}
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </>
    );
});
export default EditInvoice;