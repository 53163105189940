import { ISwitchBackOfficeMerhcnatRequest } from "@/interfaces/Requests/Requests";
import { ITranslation } from "@/interfaces/Responses/Responses";
import { IMerchantPortalOptionSettingViewModel, IMerchantPortalStyleSetting, IOperationResultT, IQueryOptionViewModel } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useSwitchBackOfficeMerhcnatApi = (params: ISwitchBackOfficeMerhcnatRequest) => useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/v1/Merchant/SwitchBackOfficeMerhcnat`, {
    method: 'POST',
    body: params,
});

export const useGetSupportedLanguagesApi = () => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetSupportedLanguages`);

export const useGetGetTranslationsApi = (language: string) => useAPI<IOperationResultT<ITranslation[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetTranslations?language=${language}`);

export const useGetLogisticsMainTypesApi = () => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetLogisticsMainTypes`);

export const useGetSupportedCountriesApi = () => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetSupportedCountries`);

export const useGetMerchantPortalOptionSettingApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMerchantPortalOptionSettingViewModel>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetMerchantPortalOptionSetting?merchantId=${merchantId}`, {
    signal: signal,
});

export const useGetMerchantPortalStyleSettingsApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IMerchantPortalStyleSetting[]>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetMerchantPortalStyleSettings?merchantId=${merchantId}`, {
    signal: signal,
});

export const useGetQueryOptionsByMerchantIdApi = (merchantId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IQueryOptionViewModel>>(`${process.env.BASE_API_URL}/api/v1/Portal/GetQueryOptionsByMerchantId?merchantId=${merchantId}`, {
    signal
});