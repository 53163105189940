import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Flex } from "antd";
import Title from "antd/es/typography/Title";
import { load } from "cheerio";
import htmlEditButton from "quill-html-edit-button";
import ImageResize from 'quill-image-resize-module-react';
import React, { useContext, useEffect } from "react";
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { useMutation } from "react-query";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { useUploadImageApi } from "@/lib/api/images";
import { ImageUseageType } from "@/enums/Enums";

/**
 * @interface IDescriptionProps
 * @說明 描述表單的屬性
 */
export interface IDescriptionProps {
    previewOnly?: boolean;
}

/**
 * @interface IDescriptionRef
 * @說明 描述表單Ref
 */
export interface IDescriptionRef {
    validate: () => Promise<boolean>;
}

// 註冊quill模組
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);

/**
 * @component AlterDescription
 * @說明 用於顯示與編輯描述內容，可以在WYSIWYG與HTML之間切換
 */
const AlterDescription: React.FC<IDescriptionProps> = ({ previewOnly }) => {
    const { itemViewModel } = useContext(ItemContext);
    const { translate } = useContext(TranslationContext);
    const { messageApi } = useContext(GlobalContext);
    const quillRef = React.useRef<Quill | null>(null);
    const editorRef = React.useRef<HTMLDivElement>(null);
    const [content, setContent] = React.useState(itemViewModel.description || '');

    // 添加圖片上傳mutation
    const upLoadImageMutation = useMutation(async (request: FormData) => await useUploadImageApi(request));

    // 處理圖片上傳
    const handleImageUpload = async () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files?.[0];
            if (file) {
                try {
                    const formData = new FormData();
                    formData.append('UseageType', ImageUseageType.ItemDescription);
                    formData.append('file', file);

                    const response = await upLoadImageMutation.mutateAsync(formData);

                    if (response.isSuccess && response.result) {
                        const quill = quillRef.current;
                        const range = quill?.getSelection(true);

                        // 在游標位置插入圖片
                        if (range) {
                            quill?.insertEmbed(range.index, 'image', response.result);
                        }
                    }
                } catch (error) {
                    messageApi.error('Upload failed');
                }
            }
        };
    };

    /**
     * @function onDescriptionChange
     * @說明 當使用者在編輯器中輸入或變更內容時，自動幫所有元素加上 max-width:100%
     */
    const onDescriptionChange = (value: string) => {
        const $ = load(value);
        $('*').each((index, element) => {
            const existingStyle = $(element).attr('style') || '';
            const newStyle = `${existingStyle} max-width: 100%;`;
            $(element).attr('style', newStyle);
        });
        return $.html();
    };

    // ReactQuill的模組設定
    var modules: any = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['clean']
            ],
            handlers: {
                image: handleImageUpload
            }
        },
        htmlEditButton: {
            buttonHTML: 'HTML',
            msg: translate('Edit HTML here'),
            okText: translate('Save'),
            cancelText: translate('Cancel')
        }
    };

    // 如果不是僅預覽，就啟用圖片縮放
    if (!previewOnly) {
        modules = {
            ...modules,
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            }
        };
    }

    // 格式設定
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'size', 'color', 'background', 'font', 'align',
        'link', 'image'
    ];

    useEffect(() => {
        if (editorRef.current && !quillRef.current) {
            const quill = new Quill(editorRef.current, {
                theme: 'snow',
                modules: modules,
                formats: formats,
                placeholder: translate('Write something amazing...'),
                readOnly: previewOnly
            });

            quill.on('text-change', () => {
                const value = quill.root.innerHTML;
                const processedValue = onDescriptionChange(value);
                setContent(processedValue); // 先更新 React 狀態

                // 確保 itemViewModel.description 被更新
                if (itemViewModel) {
                    itemViewModel.description = processedValue;
                    console.log('Description updated:', processedValue); // 添加日誌以便調試
                }
            });

            // 設置初始值
            if (content) {
                quill.root.innerHTML = content;
            }

            quillRef.current = quill;
        }
    }, []);

    // 監聽 itemViewModel.description 的變化
    useEffect(() => {
        if (quillRef.current && itemViewModel.description !== content) {
            quillRef.current.root.innerHTML = itemViewModel.description || '';
            setContent(itemViewModel.description || '');
        }
    }, [itemViewModel.description]);

    return (
        <div style={{ width: '100%' }}>
            <Flex justify="center">
                <Title level={3}>{translate('Description')}</Title>
            </Flex>
            <div style={{ padding: '30px', width: '100%' }}>
                <div ref={editorRef} />
            </div>
        </div>
    );
};

export default AlterDescription;
