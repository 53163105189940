import { useGetDashboardMonthlyStatisticsApi, useGetDashboardStatisticsApi } from '@/lib/api/dataAnalysis';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { ArrowDownOutlined, ArrowUpOutlined, MinusOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Statistic, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useMutation } from 'react-query';
import { DataAnalysisPageContext } from '../Contexts/DataAnalysisPageContext';

const StatisticCard: React.FC<{
    title: string;
    total: number;
    month: number;
    growth: number;
    precision?: number;
    loading?: boolean;
}> = ({ title, total, month, growth, precision, loading }) => {
    const { translate } = useContext(TranslationContext);

    const getGrowthColor = (growth: number) => {
        if (growth > 0) return '#ff4d4f';
        if (growth < 0) return '#52c41a';
        return '#8c8c8c';
    };

    const getGrowthIcon = (growth: number) => {
        if (growth > 0) return <ArrowUpOutlined />;
        if (growth < 0) return <ArrowDownOutlined />;
        return <MinusOutlined />;
    };

    return (
        <Card loading={loading}>
            <Statistic
                title={translate(title)}
                value={total}
                precision={precision}
            />
            <Divider style={{ margin: '12px 0' }} />
            <Row justify="space-between" align="middle">
                <Col>
                    <Statistic
                        value={month}
                        precision={precision}
                        valueStyle={{ fontSize: '14px' }}
                        prefix={<Typography.Text type="secondary">{translate('This Month')}:</Typography.Text>}
                    />
                </Col>
                <Col>
                    <Statistic
                        value={Math.abs(growth)}
                        precision={2}
                        valueStyle={{
                            fontSize: '14px',
                            color: getGrowthColor(growth)
                        }}
                        prefix={getGrowthIcon(growth)}
                        suffix="%"
                    />
                </Col>
            </Row>
        </Card>
    );
};

const DataStatistics: React.FC = () => {
    const { abortController } = useContext(DataAnalysisPageContext);
    const { data, mutate, isLoading } = useMutation(
        async () => await useGetDashboardStatisticsApi(abortController.current.signal),
    );
    const { data: monthlyData, mutate: mutateMonthlyData, isLoading: isLoadingMonthlyData } = useMutation(
        async () => await useGetDashboardMonthlyStatisticsApi(abortController.current.signal),
    );

    useEffect(() => {
        mutate();
        mutateMonthlyData();
    }, []);

    const statistics = data?.result || {
        totalSales: 0,
        totalRevenue: 0,
        totalCustomers: 0
    };

    return (
        <Row gutter={16}>
            <Col span={8}>
                <StatisticCard
                    title="Total Sales"
                    total={statistics.totalSales}
                    month={monthlyData?.result?.monthSales || 0}
                    growth={monthlyData?.result?.salesGrowth || 0}
                    loading={isLoading || isLoadingMonthlyData}
                />
            </Col>
            <Col span={8}>
                <StatisticCard
                    title="Total Revenue"
                    total={statistics.totalRevenue}
                    month={monthlyData?.result?.monthRevenue || 0}
                    growth={monthlyData?.result?.revenueGrowth || 0}
                    precision={2}
                    loading={isLoading || isLoadingMonthlyData}
                />
            </Col>
            <Col span={8}>
                <StatisticCard
                    title="Total Customers"
                    total={statistics.totalCustomers}
                    month={monthlyData?.result?.monthCustomers || 0}
                    growth={monthlyData?.result?.customersGrowth || 0}
                    loading={isLoading || isLoadingMonthlyData}
                />
            </Col>
        </Row>
    );
};

export default DataStatistics;
