import { CheckOutStep, FrontEndDisplayType, ThirdPartyPaymentCreateResponseTypeEnum } from "@/Templates/enums/templateEnums";
import { IMemberLogisticOption, IOperationResult, IOperationResultT, IPortalMerchantThirdPartyLogisticSettingViewModel, IPortalMerchantThirdPartyPaymentSettingViewModel, IShoppingCartItem, IShoppingCartItemViewModel, IShoppingCartViewModel, ISupportMemberFillAddressResponse, ITempShoppingCartViewModel, IThirdPartyPaymentCreateRespnse } from "@/Templates/interfaces/templatesInterfaces";
import { useAddMemberLogisticOptionApi, useCreatePaymentOrder, useDeleteMemberLogisticOptionApi, useFillMemberLogisticOptionWithThirdPartyLogisticApi, useFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistApi, useGetAnonymousLogisticOptionsApi, useGetLogisticsMainTypesApi, useGetMemberLogisticOptionsApi, useGetMerchantThirdPartyLogisticSettingViewModes, useGetPaymentMainTypesApi, useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi, useGetShoppingCartApi, useGetTempShoppingCartApi } from '@/Templates/lib/apis';
import { UrlHelper } from '@/Templates/lib/UrlHelper';
import { MessageInstance } from 'antd/es/message/interface';
import JSONBig from 'json-bigint';
import { createContext, ReactNode, useCallback, useContext, useState } from "react";
import { useMutation, UseMutationResult, useQuery } from 'react-query';

export interface ICheckOutContext {
    selectedItems: IShoppingCartItem[];
    setSelectedItems: React.Dispatch<React.SetStateAction<IShoppingCartItem[]>>;
    selectedLogistic: IPortalMerchantThirdPartyLogisticSettingViewModel | undefined;
    setSelectedLogistic: React.Dispatch<React.SetStateAction<IPortalMerchantThirdPartyLogisticSettingViewModel | undefined>>;
    selectedLogisticOption: IMemberLogisticOption | undefined;
    setSelectedLogisticOption: React.Dispatch<React.SetStateAction<IMemberLogisticOption | undefined>>;
    isOnlinePayment: boolean;
    setIsOnlinePayment: React.Dispatch<React.SetStateAction<boolean>>;
    selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined;
    setSelectedPayment: React.Dispatch<React.SetStateAction<IPortalMerchantThirdPartyPaymentSettingViewModel | undefined>>;
    currentStep: CheckOutStep;
    setCurrentStep: React.Dispatch<React.SetStateAction<CheckOutStep>>;
    userWantReservePay: boolean;
    setUserWantReservePay: React.Dispatch<React.SetStateAction<boolean>>;
    logisticValues: Record<string, string>;
    setLogisticValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    paymentValues: Record<string, string>;
    setPaymentValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    logiscticSupportedPaymentSettingIds: BigInt[];
    setLogiscticSupportedPaymentSettingIds: React.Dispatch<React.SetStateAction<BigInt[]>>;
    receiverInfos: any;
    setReceiverInfos: React.Dispatch<React.SetStateAction<any>>;
    invoiceCountry: string;
    setInvoiceCountry: React.Dispatch<React.SetStateAction<string>>;
    invoiceValues: Record<string, string>;
    setInvoiceValues: React.Dispatch<React.SetStateAction<Record<string, string>>>;
    invoiceValidate: boolean;
    setInvoiceValidate: React.Dispatch<React.SetStateAction<boolean>>;
    shoppingCartDataMutation: UseMutationResult<IOperationResultT<IShoppingCartViewModel> | IOperationResultT<ITempShoppingCartViewModel>, unknown, void, unknown>;
    paymentMainTypesMutation: UseMutationResult<IOperationResultT<string[]>, unknown, { signal?: AbortSignal | undefined }, unknown>;
    merchantPortalPaymentSettingsMutation: UseMutationResult<IOperationResultT<IPortalMerchantThirdPartyPaymentSettingViewModel[]>, unknown, { merchantId: BigInt; signal?: AbortSignal | undefined }, unknown>;
    logisticsMainTypes: string[] | undefined;
    logisticMethods: IPortalMerchantThirdPartyLogisticSettingViewModel[] | undefined;
    handleAddMemberLogisticOption: UseMutationResult<IOperationResult | undefined, unknown, Record<string, string>, unknown>;
    handleDeleteMemberLogisticOption: UseMutationResult<IOperationResult | undefined, unknown, BigInt, unknown>;
    sendPaymentRequest: UseMutationResult<IOperationResultT<IThirdPartyPaymentCreateRespnse> | undefined, unknown, {
        orderId: BigInt;
        paymentValues: Record<string, string>;
        selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined;
    }, unknown>;
    handleAddStoreAddressOption: UseMutationResult<IOperationResultT<ISupportMemberFillAddressResponse> | undefined, unknown, IPortalMerchantThirdPartyLogisticSettingViewModel | undefined, unknown>;
    refetchLogisticsMainTypes: () => void;
    refetchLogisticMethods: () => void;
    memberLogisticOptionsMutation: UseMutationResult<IOperationResultT<IMemberLogisticOption[]>, unknown, { isLogin: boolean, signal?: AbortSignal | undefined }, unknown>;
    nextStep: () => void;
    prevStep: () => void;
    handleSelectAll: (items: IShoppingCartItemViewModel[], isSelected: boolean) => void;
    handleSelectItem: (item: IShoppingCartItemViewModel, checked: boolean) => void;
    memberSelectedParameters: Record<string, string>;
    setMemberSelectedParameters: (key: string, value: string) => void;
    selectedMemberLogisticOption: IMemberLogisticOption | undefined;
    setSelectedMemberLogisticOption: React.Dispatch<React.SetStateAction<IMemberLogisticOption | undefined>>;
    isAddingStoreAddress: boolean;
    isDeletingLogisticOption: boolean;
    note: string;
    setNote: React.Dispatch<React.SetStateAction<string>>;
}

export const CheckOutContext = createContext<ICheckOutContext>(null!);

export const CheckOutProvider = ({
    translate,
    children,
    merchantId,
    isLogin,
    tryGetSessionId,
    messageApi
}: {
    translate: (key: string) => string;
    children: ReactNode,
    merchantId: BigInt,
    isLogin: boolean,
    tryGetSessionId: () => BigInt,
    messageApi: MessageInstance
}) => {
    const urlHelper = new UrlHelper();
    const [selectedItems, setSelectedItems] = useState<IShoppingCartItem[]>([]);
    const [selectedLogistic, setSelectedLogistic] = useState<IPortalMerchantThirdPartyLogisticSettingViewModel | undefined>(undefined);
    const [selectedLogisticOption, setSelectedLogisticOption] = useState<IMemberLogisticOption | undefined>(undefined);
    const [isOnlinePayment, setIsOnlinePayment] = useState<boolean>(false);
    const [selectedPayment, setSelectedPayment] = useState<IPortalMerchantThirdPartyPaymentSettingViewModel | undefined>(undefined);
    const [currentStep, setCurrentStep] = useState<CheckOutStep>(CheckOutStep.SelectItem);
    const [userWantReservePay, setUserWantReservePay] = useState<boolean>(false);
    const [logisticValues, setLogisticValues] = useState<Record<string, string>>({});
    const [paymentValues, setPaymentValues] = useState<Record<string, string>>({});
    const [logiscticSupportedPaymentSettingIds, setLogiscticSupportedPaymentSettingIds] = useState<BigInt[]>([]);
    const [receiverInfos, setReceiverInfos] = useState<any>({});
    const [invoiceCountry, setInvoiceCountry] = useState<string>('Unset');
    const [invoiceValues, setInvoiceValues] = useState<Record<string, string>>({});
    const [invoiceValidate, setInvoiceValidate] = useState<boolean>(false);
    const [memberSelectedParameters, setMemberSelectedParameters] = useState<Record<string, string>>({});
    const [selectedMemberLogisticOption, setSelectedMemberLogisticOption] = useState<IMemberLogisticOption | undefined>(undefined);
    const [isAddingStoreAddress, setIsAddingStoreAddress] = useState(false);
    const [isDeletingLogisticOption, setIsDeletingLogisticOption] = useState(false);
    const [note, setNote] = useState<string>('');
    // 新增 API 相關的查詢和變異
    const shoppingCartDataMutation = useMutation(
        ["GetShoppingCartApi", merchantId, isLogin],
        async () => isLogin ? await useGetShoppingCartApi(tryGetSessionId()) : await useGetTempShoppingCartApi(merchantId, tryGetSessionId()),
    );

    const paymentMainTypesMutation = useMutation(
        'getPaymentMainTypes',
        (params: { signal?: AbortSignal | undefined }) => useGetPaymentMainTypesApi()
    );

    const merchantPortalPaymentSettingsMutation = useMutation(
        ['getMerchantThirdPartyPaymentSettingViewModels', merchantId],
        (params: { merchantId: BigInt; signal?: AbortSignal | undefined }) => useGetPortalMerchantThirdPartyPaymentSettingViewModelsApi(params.merchantId, params.signal)
    );

    const { data: logisticsMainTypesData, refetch: refetchLogisticsMainTypes } = useQuery(
        'getLogisticsMainTypesApi',
        () => useGetLogisticsMainTypesApi()
    );

    const { data: logisticMethodsData, refetch: refetchLogisticMethods } = useQuery(
        ['getMerchantThirdPartyLogisticSettingViewModes', merchantId],
        () => useGetMerchantThirdPartyLogisticSettingViewModes(merchantId)
    );

    const memberLogisticOptionsMutation = useMutation(
        async (params: { isLogin: boolean, signal?: AbortSignal | undefined }) => params.isLogin ? await useGetMemberLogisticOptionsApi(merchantId, params.signal) : await useGetAnonymousLogisticOptionsApi(tryGetSessionId(), params.signal),
    );

    const handleAddMemberLogisticOption = useMutation(async (values: Record<string, string>, signal?: AbortSignal | undefined) => {
        if (!selectedLogistic) {
            throw new Error(translate("No logistic selected"));
        }

        const response = await useAddMemberLogisticOptionApi({
            thirdPartyLogisticId: selectedLogistic.thirdPartySettingId,
            values
        });

        if (!response.isSuccess) {
            return response;
        }

        messageApi.success(translate('Address added successfully'));
        memberLogisticOptionsMutation.mutate({ isLogin, signal });
    });

    const handleDeleteMemberLogisticOption = useMutation(async (memberLogisticOptionId: BigInt, signal?: AbortSignal | undefined) => {
        const response = await useDeleteMemberLogisticOptionApi({
            sessionId: tryGetSessionId(),
            memberLogisticOptionId
        });

        if (!response.isSuccess) {
            return response;
        }

        messageApi.success(translate('Address deleted successfully'));
        memberLogisticOptionsMutation.mutate({ isLogin, signal });
    });

    const sendPaymentRequest = useMutation(async ({ orderId, paymentValues, selectedPayment }: { orderId: BigInt, paymentValues: Record<string, string>, selectedPayment: IPortalMerchantThirdPartyPaymentSettingViewModel | undefined }) => {
        if (!selectedPayment) {
            throw new Error(translate("Please select a payment method"));
        }

        const frontEndValues = { ...paymentValues, ['FrontEndHostDomain']: `${window.location.protocol}//${window.location.host}` };
        const response = await useCreatePaymentOrder({
            orderId: orderId,
            settingId: selectedPayment.thirdPartySettingId,
            frontEndValues: frontEndValues
        });

        if (!response.isSuccess) {
            return response;
        }

        if (response.isSuccess && response.result) {
            const data = JSONBig.parse(response.result?.data);
            switch (response.result?.thirdPartyPaymentCreateResponseTypeEnum) {
                case ThirdPartyPaymentCreateResponseTypeEnum.FormPost:
                    urlHelper.pageFormPost(response.result?.url, data);
                    break;
                case ThirdPartyPaymentCreateResponseTypeEnum.Redirect:
                    urlHelper.openRedirect(response.result?.url, data);
                    break;
                case ThirdPartyPaymentCreateResponseTypeEnum.Html:
                    urlHelper.openHtml(response.result?.url, data);
                    break;
                case ThirdPartyPaymentCreateResponseTypeEnum.QRCode:
                    urlHelper.openQRCode(response.result?.url, data);
                    break;
                default:
                    break;
            }
        }
    });

    const handleAddStoreAddressOption = useMutation(async (logisticVM: IPortalMerchantThirdPartyLogisticSettingViewModel | undefined) => {
        if (!logisticVM) return;

        const frontEndValues: { [key: string]: string } = {
            ...logisticValues,
            'MerchantID': merchantId.toString(),
            'IsCollection': logisticVM.isSupportReservePay ? userWantReservePay.toString() : 'false',
            'FrontEndHostDomain': `${window.location.protocol}//${window.location.host}`
        };

        const apiFunction = isLogin
            ? useFillMemberLogisticOptionWithThirdPartyLogisticApi
            : useFillMemberLogisticOptionWithThirdPartyLogisticWithNoRegistApi;

        const requestParams = {
            merchantId,
            thirdPartyLogisticId: selectedLogistic?.thirdPartySettingId!,
            frontEndValues,
            sessionId: tryGetSessionId()
        };

        const response = await apiFunction(requestParams);
        if (!response.isSuccess) {
            return response;
        }

        if (response.isSuccess && response.result) {
            const data = JSONBig.parse(response.result?.data);
            switch (response.result?.type) {
                case FrontEndDisplayType.PageFormPost:
                    urlHelper.pageFormPost(response.result?.url, data);
                    break;
                case FrontEndDisplayType.PageRedirectURL:
                    urlHelper.openRedirect(response.result?.url, data);
                    break;
                case FrontEndDisplayType.OpenNewPage:
                    urlHelper.openInPopup(response.result?.url, data);
                    break;
                default:
                    break;
            }
        }
    });

    const nextStep = useCallback(() => {
        setCurrentStep(prevStep => (prevStep === CheckOutStep.Logistic && userWantReservePay) ? CheckOutStep.Confirm : prevStep + 1);
    }, [userWantReservePay]);

    const prevStep = useCallback(() => {
        setCurrentStep(prevStep => (prevStep === CheckOutStep.Confirm && userWantReservePay) ? CheckOutStep.Logistic : prevStep - 1);
    }, [userWantReservePay]);

    const handleSelectAll = useCallback((items: IShoppingCartItemViewModel[], isSelected: boolean) => {
        setSelectedItems(isSelected ? items : []);
    }, []);

    const handleSelectItem = useCallback((item: IShoppingCartItemViewModel, checked: boolean) => {
        // 強制轉換類型
        const shoppingCartItem: IShoppingCartItem = {
            itemId: item.itemId,
            itemSpecId: item.itemSpecId,
            itemIcon: item.itemIcon,
            itemName: item.itemName,
            currency: item.currency,
            logisticOrderLimitType: item.logisticOrderLimitType,
            itemDescription: item.itemDescription,
            itemSpecName: item.itemSpecName,
            itemSpecPrice: item.itemSpecPrice,
            buyAmount: item.buyAmount
        }

        if (checked) {
            // 檢查是否已經選擇過此商品
            const isDuplicate = selectedItems.some(selectedItem =>
                selectedItem.itemId.toString() === shoppingCartItem.itemId.toString() &&
                selectedItem.itemSpecId.toString() === shoppingCartItem.itemSpecId.toString()
            );

            if (isDuplicate) {
                return;
            }

            // 檢查幣別和物流限制是否一致
            if (selectedItems.length > 0) {
                if (selectedItems[0].currency !== shoppingCartItem.currency) {
                    messageApi.error(`${translate('只能選擇相同幣別的商品')}`);
                    return;
                }
                if (selectedItems[0].logisticOrderLimitType !== shoppingCartItem.logisticOrderLimitType) {
                    messageApi.error(`${translate('只能選擇相同物流限制類型的商品')}`);
                    return;
                }
            }

            // 使用 Set 來確保不會有重複項目
            const newSelectedItems = new Set([...selectedItems, shoppingCartItem]);
            setSelectedItems(Array.from(newSelectedItems));
        } else {
            // 移除選中的商品
            setSelectedItems(prevItems =>
                prevItems.filter(i =>
                    !(i.itemId.toString() === shoppingCartItem.itemId.toString() &&
                        i.itemSpecId.toString() === shoppingCartItem.itemSpecId.toString())
                )
            );
        }
    }, [selectedItems, messageApi, translate]);

    return (
        <CheckOutContext.Provider
            value={{
                selectedItems,
                setSelectedItems,
                selectedLogistic,
                setSelectedLogistic,
                selectedLogisticOption,
                setSelectedLogisticOption,
                isOnlinePayment,
                setIsOnlinePayment,
                selectedPayment,
                setSelectedPayment,
                currentStep,
                setCurrentStep,
                userWantReservePay,
                setUserWantReservePay,
                logisticValues,
                setLogisticValues,
                paymentValues,
                setPaymentValues,
                logiscticSupportedPaymentSettingIds,
                setLogiscticSupportedPaymentSettingIds,
                receiverInfos,
                setReceiverInfos,
                invoiceCountry,
                setInvoiceCountry,
                invoiceValues,
                setInvoiceValues,
                invoiceValidate,
                setInvoiceValidate,
                shoppingCartDataMutation,
                handleAddMemberLogisticOption,
                handleDeleteMemberLogisticOption,
                sendPaymentRequest: sendPaymentRequest,
                handleAddStoreAddressOption,
                refetchLogisticsMainTypes,
                refetchLogisticMethods,
                memberLogisticOptionsMutation,
                paymentMainTypesMutation,
                merchantPortalPaymentSettingsMutation,
                logisticsMainTypes: logisticsMainTypesData?.isSuccess ? logisticsMainTypesData.result : [],
                logisticMethods: logisticMethodsData?.isSuccess ? logisticMethodsData.result : [],
                nextStep,
                prevStep,
                handleSelectAll,
                handleSelectItem,
                memberSelectedParameters,
                setMemberSelectedParameters: (key, value) => {
                    setMemberSelectedParameters(prev => ({ ...prev, [key]: value }));
                },
                selectedMemberLogisticOption,
                setSelectedMemberLogisticOption,
                isAddingStoreAddress,
                isDeletingLogisticOption,
                note,
                setNote
            }}
        >
            {children}
        </CheckOutContext.Provider>
    );
};

export const useCheckOut = () => useContext(CheckOutContext);