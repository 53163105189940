import { MerchantRoleEnum } from "@/enums/Enums";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { Row } from "antd";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Marquee from "react-fast-marquee";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import routes from '../../../constants/routes';

const MyRoutes = () => {
  const {
    isLogin,
    messageApi,
    navBarHeight,
    isBackOffice,
    merchantPermissionRoles,
    isSetMerchantIdAggregateLoading,
    getMerchantIdFromLocalStorage,
    removeMerchantIdAggregate,
    setMerchantIdAggregate,
    setIsBackOffice,
    isHideAnnouncement,
    isInitialized,
  } = useContext(GlobalContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [rolesLoaded, setRolesLoaded] = useState(false);

  // Function to check if the user has the required roles and permissions
  const hasRequiredRolesAndPermissions = useCallback((routeName: string, islogin: boolean, ignoreLogin?: boolean, requiredPermissionRoles?: string[]) => {
    // 允許未登入使用頁面
    if (ignoreLogin) {
      return true;
    }
    // UserInfoPage 有登入即可進入
    if (routeName === routes['UserInfoPage'].path) {
      return islogin;
    }
    // Treat empty array as null
    const effectivePermissionRoles = requiredPermissionRoles?.length ? requiredPermissionRoles : null;

    // 检查是否是后端成员（是否登入）
    const hasRequiredIdentityRole = isBackOffice;

    // 检查商户权限
    const hasRequiredMerchantRole = !effectivePermissionRoles ||
      effectivePermissionRoles.some(role => merchantPermissionRoles?.includes(role)) ||
      merchantPermissionRoles?.includes(MerchantRoleEnum.Owner);

    return hasRequiredIdentityRole && hasRequiredMerchantRole;
  }, [isBackOffice, merchantPermissionRoles]);

  const requireRole = useCallback((routeName: string, element: React.ReactElement, islogin: boolean, ignoreIsBackOffice?: boolean, requiredPermissionRoles?: string[]) => {
    if (hasRequiredRolesAndPermissions(routeName, islogin, ignoreIsBackOffice, requiredPermissionRoles)) {
      return element;
    }
    // Redirect to HomePage if the user does not have the required roles
    return routes['HomePage'].element;
  }, [hasRequiredRolesAndPermissions]);

  const routeElements = useMemo(() => {
    return Object.values(routes).map((route, index) => (
      <Route
        key={index}
        path={route.path}
        element={requireRole(route.path, route.element, isLogin, route.ignoreLogin, route.merchantPermissionRoles)}
      />
    ));
  }, [requireRole]);

  return (
    <div style={{ paddingTop: `${navBarHeight}px` }}>
      <Row>
        {/* Announcement */}
        {!isHideAnnouncement &&
          <Marquee pauseOnHover gradient={true} gradientColor='gray' gradientWidth={'50%'}>
            Announcement
          </Marquee>
        }
      </Row>
      <Routes>
        {routeElements}
      </Routes>
    </div>
  );
};

export default MyRoutes;
