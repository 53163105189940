import { Button } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import lineIcon from '../../assets/Line/LINE_Brand_icon.png';

export interface ILineLoginProps {

}

const LineLogin = (props: ILineLoginProps) => {
    const clientId = process.env.LineClientId;
    const redirectUri = `${process.env.LineRedirectUri}`;
    const state = window.location.origin !== new URL(redirectUri).origin ? `${encodeURI(window.location.origin)}` : `${encodeURI(window.location.href.replace('/login', ''))}`;
    const scope = 'profile%20openid%20email'; // Scopes you want to request
    const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
    return (
        <Button
            icon={
                <LazyLoadImage
                    width={20}
                    src={lineIcon}
                    effect="blur"
                    alt="Line Icon"
                />
            }
            onClick={() => {
                window.location.href = lineLoginUrl
            }}>
            LINE
        </Button>
    );
};

export default LineLogin;
