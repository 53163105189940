import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { Flex, Spin } from 'antd';
import { useContext, useEffect } from 'react';

const PageFormPost = () => {
    const { setIsHideNavBar, setIsHideAnnouncement, isInitialized } = useContext(GlobalContext);
    const { messageApi } = useContext(GlobalContext);
    const params = new URLSearchParams(location.search);
    const formValues = Array.from(params.entries()).reduce((obj: { [key: string]: any }, [key, value]) => {
        obj[key] = value;
        return obj;
    }, {});

    useEffect(() => {
        setIsHideNavBar(true);
        setIsHideAnnouncement(true);
    }, [setIsHideNavBar]);

    useEffect(() => {
        if (!isInitialized) {
            return;
        }
        if (formValues) {
            const form = document.getElementById('redirectForm') as HTMLFormElement;
            form?.submit();
        }
    }, [isInitialized]);


    return (
        <>
            <Flex style={{ position: 'fixed', width: '100%', minHeight: '100%' }} justify='center' align='center'>
                <Spin></Spin>
            </Flex>
            {formValues && (
                <form id="redirectForm" action={formValues['ApiUrl']} method={formValues['Method'] || 'post'}>
                    {
                        Object.entries(formValues)
                            .filter(([key]) => key !== 'ApiUrl')
                            .map(([key, value]) => (
                                <input hidden name={key} value={value} key={key} onChange={() => { }} />
                            ))
                    }
                </form>
            )}
        </>
    );
};

export default PageFormPost;
