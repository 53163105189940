import { IChildrenProviderProps } from "@/interfaces/GlobalContextType";
import React from "react";


export interface IThirdPartyValuesContext {
    memberFieldsForCreate: string[];
    setMemberFieldsForCreate: React.Dispatch<React.SetStateAction<string[]>>;
    merchantFieldsForCreate: string[];
    setMerchantFieldsForCreate: React.Dispatch<React.SetStateAction<string[]>>;
}
export const ThirdPartyValuesContext = React.createContext<IThirdPartyValuesContext>(null!);
export const ThirdPartyValuesContextProvider: React.FC<IChildrenProviderProps> = ({ children }): React.ReactNode => {
    const [memberFieldsForCreate, setMemberFieldsForCreate] = React.useState<string[]>([]);
    const [merchantFieldsForCreate, setMerchantFieldsForCreate] = React.useState<string[]>([]);
    return (
        <ThirdPartyValuesContext.Provider
            value={
                {
                    memberFieldsForCreate,
                    setMemberFieldsForCreate,
                    merchantFieldsForCreate,
                    setMerchantFieldsForCreate
                }
            }>
            {children}
        </ThirdPartyValuesContext.Provider>
    );
}
