import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { DeviceType } from "@/Templates/enums/templateEnums";
import { Tabs, TabsProps } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InvoiceModule from "./Components/Invoice/InvoiceModule";
import LogisticModule from "./Components/Logistic/LogisticModule";
import PaymentModule from "./Components/Payment/PaymentModule";
import PlatformServiceList from "./Components/PlatformService/PlatformServiceList";

type ModuleKey = 'payment' | 'logistic' | 'invoice' | 'platformService';

const ThirdPartyPage = () => {
    const { deviceType } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [activeModuleKey, setActiveModuleKey] = useState<ModuleKey>('payment');

    useEffect(() => {
        // 從 URL 讀取初始 module
        const moduleFromUrl = searchParams.get('module') as ModuleKey;
        if (moduleFromUrl && ['payment', 'logistic', 'invoice', 'platformService'].includes(moduleFromUrl)) {
            setActiveModuleKey(moduleFromUrl);
        }
    }, []);

    const handleModuleChange = (key: string) => {
        setActiveModuleKey(key as ModuleKey);
        // 更新 URL
        navigate(`?module=${key}${searchParams.get('tab') ? `&tab=${searchParams.get('tab')}` : ''}`);
    };

    const items: TabsProps['items'] = [
        {
            key: 'payment',
            label: translate('Payment'),
            children: <PaymentModule />,
        },
        {
            key: 'logistic',
            label: translate('Logistic'),
            children: <LogisticModule />,
        },
        {
            key: 'invoice',
            label: translate('Invoice'),
            children: <InvoiceModule />,
        },
        {
            key: 'platformService',
            label: translate('Social Platform'),
            children: <PlatformServiceList />,
        }
    ];

    return (
        <Tabs
            activeKey={activeModuleKey}
            items={items}
            onChange={handleModuleChange}
            destroyInactiveTabPane={true}
            tabPosition={deviceType === DeviceType.Mobile ? 'top' : 'left'}
        />
    );
};

export default ThirdPartyPage;