import { OrderStatus } from '@/enums/Enums';
import { LogisticOrderType } from '@/enums/ThirdPartyLogistic';
import { IBatchOrderRequest } from '@/interfaces/Requests/Requests';
import { IOrder } from '@/interfaces/Responses/Responses';
import { useBatchOrderCancelApi, useBatchOrderConfirmApi } from '@/lib/api/orders';
import { useBatchCreateThirdPartyLogisticOrderApi, useBatchQueryThirdPartyOrderStatusApi } from '@/lib/api/thirdPartyLogistics';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { UrlHelper } from '@/Templates/lib/UrlHelper';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal, Space } from 'antd';
import JSONBig from 'json-bigint';
import React, { useCallback, useContext } from 'react';

interface BatchOperationProps {
    disabled: boolean;
    selectedRows: IOrder[];
    onSuccess: () => void;
}
/**
 * 批量操作組件
 */
const BatchOperation: React.FC<BatchOperationProps> = ({
    disabled,
    selectedRows,
    onSuccess
}) => {
    const { translate } = useContext(TranslationContext);
    const { messageApi } = useContext(GlobalContext);
    const { confirm } = Modal;
    const urlHelper = new UrlHelper();

    // 檢查是否可以創建物流訂單
    const canCreateLogisticOrder = selectedRows.every(
        order => order.status === OrderStatus.WaitForShipment
    );

    // 檢查是否可以查詢物流狀態
    const canQueryLogisticStatus = selectedRows.some(order => [
        OrderStatus.DuringShipment,
        OrderStatus.ShipmentDispute,
        OrderStatus.WaitForBuyerPickup,
        OrderStatus.BuyerNotPickup,
        OrderStatus.ReturnToLogisticsCenter,
        OrderStatus.BuyerApplyRefund,
        OrderStatus.WaitForMerchantConfirmRefundApplication,
        OrderStatus.WaitForReturnShipment,
        OrderStatus.DuringReturnShipment,
        OrderStatus.ReturnShipmentDispute,
        OrderStatus.WaitForMerchantPickupRefund,
        OrderStatus.MerchantNotPickup,
        OrderStatus.WaitForMerchantConfirmRefundItems,
        OrderStatus.WaitForMerchantRefundAmount,
        OrderStatus.RefundDone,
        OrderStatus.RefundUnderDispute
    ].includes(order.status));

    /**
     * 處理批量確認訂單
     */
    const handleBatchConfirm = useCallback(async () => {
        confirm({
            title: translate('Please confirm again'),
            icon: <ExclamationCircleTwoTone twoToneColor="red" />,
            content: translate('Are you sure to confirm these orders?'),
            okText: translate('Yes'),
            cancelText: translate('No'),
            onOk: async () => {
                const request: IBatchOrderRequest = {
                    orderIds: selectedRows.map(row => row.id)
                };

                try {
                    const response = await useBatchOrderConfirmApi(request);
                    if (response.isSuccess) {
                        messageApi.success(translate('Orders confirmed successfully'));
                        onSuccess();
                    } else {
                        messageApi.error(translate(response.message || 'Failed to confirm orders'));
                    }
                } catch (error) {
                    messageApi.error(translate('An error occurred while confirming orders'));
                }
            }
        });
    }, [selectedRows, translate, messageApi, onSuccess]);

    /**
     * 處理批量取消訂單
     */
    const handleBatchCancel = useCallback(async () => {
        confirm({
            title: translate('Please confirm again'),
            icon: <ExclamationCircleTwoTone twoToneColor="red" />,
            content: translate('Are you sure to cancel these orders?'),
            okText: translate('Yes'),
            cancelText: translate('No'),
            onOk: async () => {
                const request: IBatchOrderRequest = {
                    orderIds: selectedRows.map(row => row.id)
                };

                try {
                    const response = await useBatchOrderCancelApi(request);
                    if (response.isSuccess) {
                        messageApi.success(translate('Orders cancelled successfully'));
                        onSuccess();
                    } else {
                        messageApi.error(translate(response.message || 'Failed to cancel orders'));
                    }
                } catch (error) {
                    messageApi.error(translate('An error occurred while cancelling orders'));
                }
            }
        });
    }, [selectedRows, translate, messageApi, onSuccess]);

    /**
     * 處理批量創建物流訂單
     */
    const handleBatchCreateLogisticOrder = useCallback(async () => {
        confirm({
            title: translate('Please confirm again'),
            icon: <ExclamationCircleTwoTone twoToneColor="blue" />,
            content: translate('Are you sure to create logistic orders?'),
            okText: translate('Yes'),
            cancelText: translate('No'),
            onOk: async () => {
                try {
                    const response = await useBatchCreateThirdPartyLogisticOrderApi({
                        requests: selectedRows.map(row => {
                            return {
                                orderId: row.id,
                                logisticOrderType: LogisticOrderType.Forward,
                                frontEndValues: {

                                },
                                settingId: BigInt(0)
                            }
                        })
                    });

                    if (response.isSuccess) {
                        messageApi.success(translate('Logistic orders created successfully'));
                        onSuccess();

                        // 處理每個物流訂單的回應
                        response.result?.forEach(result => {
                            const data = JSONBig.parse(result.data || '{}');
                            if (result.url) {
                                urlHelper.pageFormPost(result.url, data);
                            }
                        });
                    } else {
                        messageApi.error(translate(response.message || 'Failed to create logistic orders'));
                    }
                } catch (error) {
                    messageApi.error(translate('An error occurred while creating logistic orders'));
                }
            }
        });
    }, [selectedRows, translate, messageApi, onSuccess]);

    /**
     * 處理批量查詢物流狀態
     */
    const handleBatchQueryLogisticStatus = useCallback(async () => {
        try {
            const response = await useBatchQueryThirdPartyOrderStatusApi({
                thirdPartyOrderIds: selectedRows.map(row => row.id)
            });

            if (response.isSuccess) {
                messageApi.success(translate('Query successful'));
                onSuccess();

                // 處理每個查詢結果
                response.result?.forEach(result => {
                    const data = JSONBig.parse(result.data || '{}');
                    if (result.url) {
                        urlHelper.pageFormPost(result.url, data);
                    }
                });
            } else {
                messageApi.error(translate(response.message || 'Failed to query logistic status'));
            }
        } catch (error) {
            messageApi.error(translate('An error occurred while querying logistic status'));
        }
    }, [selectedRows, translate, messageApi, onSuccess]);

    // 批量操作菜單
    const menu: MenuProps = {
        items: [
            {
                key: 'confirm',
                label: translate('Batch Confirm'),
                onClick: handleBatchConfirm
            },
            {
                key: 'cancel',
                label: translate('Batch Cancel'),
                danger: true,
                onClick: handleBatchCancel
            },
            {
                key: 'createLogistic',
                label: translate('Batch Create Logistic Order'),
                disabled: !canCreateLogisticOrder,
                onClick: handleBatchCreateLogisticOrder
            },
            {
                key: 'queryLogistic',
                label: translate('Batch Query Logistic Status'),
                disabled: !canQueryLogisticStatus,
                onClick: handleBatchQueryLogisticStatus
            }
        ]
    };

    return (
        <Dropdown
            disabled={disabled}
            menu={menu}>
            <Button>
                <Space>
                    {translate('Batch Operation')}
                </Space>
            </Button>
        </Dropdown>
    );
};

export default BatchOperation; 