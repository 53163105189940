// TimeHelper.ts

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// 啟用 dayjs 的外掛
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * TimeHelper
 * 提供與時間 / 時區相關的工具方法
 */
export class TimeHelper {
    /**
     * 將一個 UTC（ISO8601）字串轉為使用者本地時區，並格式化為：
     * YYYY-MM-DD HH:mm:ss UTC+時區位移
     * @param isoString 例如 "2025-01-07T08:28:56.377564+00:00"
     * @returns 例如 "2025-01-07 16:28:56 UTC+08:00"
     */
    public static formatUtcStringToLocal(isoString: string, showTimeZone: boolean = false): string {
        // 取得使用者所在時區 (ex: "Asia/Taipei")
        const userTimeZone = dayjs.tz.guess();

        // 1. 先將字串視為 UTC
        // 2. 轉成使用者時區
        // 3. 以 "YYYY-MM-DD HH:mm:ss [UTC]Z" 格式輸出
        return dayjs.utc(isoString).tz(userTimeZone).format(showTimeZone ? 'YYYY-MM-DD HH:mm:ss [UTC]Z' : 'YYYY-MM-DD HH:mm:ss');
    }
}
