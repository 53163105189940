import { ICreateMerchantRequest, IMerchantApplyRequest } from "@/interfaces/Requests/Requests";
import { IMerchant } from "@/interfaces/Responses/Responses";
import { useCreateMerchantApi, useMerchantApplyApi } from "@/lib/api/merchants";
import { useGetSupportedCountriesApi } from "@/lib/api/portals";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Button, Card, Col, Flex, Form, Input, Modal, Select } from "antd";
import Popover from "antd/es/popover";
import React, { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

// 信息弹出组件，用于展示商户申请的信息提示
const MerchantInfomationPopover = () => {
    const { i18nLanguage } = useContext(TranslationContext);

    // 中文内容
    const contentZhTw = (
        <div>
            <p>商戶申請審核完畢，可自行操作創立商店。</p>
            <p>(商戶：商戶是指一個獨立的網站，可以有自己的商品、訂單、會員等資料。)</p>
        </div>
    );

    // 英文内容
    const contentEn = (
        <div>
            <p>Merchant application review is complete. You can now create your own store.</p>
            <p>(Merchant: A merchant refers to an independent website that can have its own products, orders, members, etc.)</p>
        </div>
    );

    // 根据当前语言展示不同的内容
    return (
        <Popover
            trigger="hover"
            content={i18nLanguage === 'zh_TW' ? contentZhTw : contentEn}
        >
            <QuestionCircleTwoTone />
        </Popover>
    );
}

// 组件属性接口
interface ApplyNewMerchantProps {
    accessibleMerchants: IMerchant[] | undefined;
}

// 主组件，处理商户申请和创建商户逻辑
const ApplyNewMerchant: React.FC<ApplyNewMerchantProps> = ({ accessibleMerchants }) => {
    const [merchantApplyForm] = Form.useForm();
    const [createNewMerchantForm] = Form.useForm();
    const navigate = useNavigate();
    const { messageApi,
        getUserInfoMutation,
        isSetMerchantIdAggregateLoading
    } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);

    // 获取支持的国家列表
    const { data: supportedCountry, mutate: supportedCountryMutate, isLoading: supportedCountryLoading } = useMutation(useGetSupportedCountriesApi);
    // 创建新商户的请求
    const { mutate: createMerchant, isLoading: isCreateMerchantLoading } = useMutation(useCreateMerchantApi, {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success('success');
                navigate(0);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    // 商户申请的请求
    const { mutate: merchantApplyMutate, isLoading: merchantApplyIsLoading } = useMutation(useMerchantApplyApi, {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success('success');
                navigate(0);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    // 组件挂载时初始化数据
    useEffect(() => {
        supportedCountryMutate();
        getUserInfoMutation.mutate();
    }, []);

    // 处理商户申请表单提交
    const handleMerchantApplySubmit = () => {
        merchantApplyForm.validateFields()
            .then((values) => {
                const selectedCountry = values.country;
                Modal.confirm({
                    title: translate('Confirm'),
                    content: `${translate('You have selected')} ${translate(`Country.${selectedCountry}`)} ${translate('Is this correct?')}`,
                    onOk() {
                        merchantApplyMutate(values as IMerchantApplyRequest);
                    },
                    onCancel() { },
                    okText: translate('Yes'),
                    cancelText: translate('No'),
                });
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    // 处理创建新商户表单提交
    const handleCreateNewMerchantSubmit = () => {
        createNewMerchantForm.validateFields()
            .then((values) => {
                const selectedCountry = values.country;
                const merchantName = values.merchantName;
                Modal.confirm({
                    title: translate('Confirm'),
                    content: (
                        <div>
                            <p>{`${translate('Country')}: ${translate(`Country.${selectedCountry}`)}`}</p>
                            <p>{`${translate('Merchant Name')}: ${merchantName}`}</p>
                            <p>{translate('Is this correct?')}</p>
                        </div>
                    ),
                    onOk() {
                        createMerchant(values as ICreateMerchantRequest);
                    },
                    onCancel() { },
                    okText: translate('Yes'),
                    cancelText: translate('No'),
                });
            })
            .catch((errorInfo) => {
                console.log('Failed:', errorInfo);
            });
    };

    if (isSetMerchantIdAggregateLoading || !accessibleMerchants || !getUserInfoMutation.data?.result?.memberId) {
        return <></>;
    }

    // 如果用户有商户角色且商户数量少于10，展示创建新商户表单
    if (accessibleMerchants.filter(r => r.ownerBackOfficeMemberId.toString() === getUserInfoMutation.data.result?.memberId.toString()).length < 10) {
        return (
            <Col span={24}>
                <Flex justify="center">
                    <Card
                        loading={isCreateMerchantLoading}
                        title={
                            <Flex align="center" justify="space-between">
                                <span>{translate('Create new merchant')}</span>
                                <MerchantInfomationPopover />
                            </Flex>
                        }
                    >
                        <Form
                            form={createNewMerchantForm}
                            layout="vertical"
                            onFinish={handleCreateNewMerchantSubmit}
                        >
                            <Form.Item
                                label={translate('Country')}
                                name="country"
                                rules={[{ required: true, message: translate('Please select country') }]}
                            >
                                <Select loading={supportedCountryLoading}>
                                    {supportedCountry?.result?.filter(r => r !== 'Unset').map(country => (
                                        <Select.Option key={country} value={country}>
                                            {translate(`Country.${country}`)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={translate('Merchant Name')}
                                name="merchantName"
                                rules={[{ required: true, message: translate('Please input merchant name') }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Flex justify="center">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={isCreateMerchantLoading}
                                    >
                                        {translate('Submit')}
                                    </Button>
                                </Flex>
                            </Form.Item>
                        </Form>
                    </Card>
                </Flex>
            </Col>
        );
    }
};

export default ApplyNewMerchant;
