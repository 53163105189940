import { Button, Form, Modal, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";

import { IGetMemberInfoViewModelByMerchantRequest } from "@/interfaces/Requests/Requests";
import { IMemberInfoViewModel, IPaginationResponse } from "@/interfaces/Responses/Responses";
import { useGetMemberInfoViewModelByMerchantApi } from "@/lib/api/members";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import type { ActionType, ProColumns, ProFormInstance } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import MemberDetail from "./Components/MemberDetail";

const { Text } = Typography;

const MemberManagePage: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { messageApi, merchantId, deviceType } = useContext(GlobalContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const actionRef = useRef<ActionType>();
    const formRef = useRef<ProFormInstance>();
    const abortController = useRef(new AbortController());

    const [memberInfoVMs, setMemberInfoVMs] = useState<IMemberInfoViewModel[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedMember, setSelectedMember] = useState<IMemberInfoViewModel | undefined>(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const initialQueryRequest: IGetMemberInfoViewModelByMerchantRequest = {
        memberId: searchParams.get('memberId') || '',
        userName: searchParams.get('userName') || '',
        email: searchParams.get('email') || '',
        phoneNumber: searchParams.get('phoneNumber') || '',
        page: Number(searchParams.get('page')) || 1,
        pageSize: Number(searchParams.get('pageSize')) || 10,
    };

    const [queryRequest, setQueryRequest] = useState<IGetMemberInfoViewModelByMerchantRequest>(initialQueryRequest);

    const { mutate, isLoading } = useMutation(
        async (request: IGetMemberInfoViewModelByMerchantRequest) =>
            await useGetMemberInfoViewModelByMerchantApi(request, abortController.current.signal),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    const paginatedResult = response.result as IPaginationResponse<IMemberInfoViewModel[]>;
                    setMemberInfoVMs(paginatedResult.data);
                    setTotalCount(paginatedResult.totalCount);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    useEffect(() => {
        // 执行初始查询
        mutate(initialQueryRequest);
    }, []);

    const updateQueryStringWithoutSearch = (newParams: Partial<IGetMemberInfoViewModelByMerchantRequest>) => {
        const updatedParams = { ...queryRequest, ...newParams };
        const newSearchParams = new URLSearchParams();
        Object.entries(updatedParams).forEach(([key, value]) => {
            if (value !== undefined && value !== '') {
                newSearchParams.set(key, value.toString());
            }
        });
        setSearchParams(newSearchParams);
    };

    const handleSearch = () => {
        const formValues = form.getFieldsValue();
        const searchRequest: IGetMemberInfoViewModelByMerchantRequest = {
            ...formValues,
            page: 1,
            pageSize: formValues.pageSize || 10
        };
        setQueryRequest(searchRequest);
        updateQueryStringWithoutSearch(searchRequest);
        mutate(searchRequest);
    };

    const handleTableChange = (pagination: any) => {
        const newRequest = {
            ...queryRequest,
            page: pagination.current,
            pageSize: pagination.pageSize
        };
        setQueryRequest(newRequest);
        updateQueryStringWithoutSearch(newRequest);
        mutate(newRequest);
    };

    const columns: ProColumns<IMemberInfoViewModel>[] = [
        {
            title: translate('Member ID'),
            dataIndex: 'memberId',
            key: 'memberId',
            render: (text, record) => record.id.toString(),
        },
        {
            title: translate('UserName'),
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: translate('Email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: translate('Phone number'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: translate('Operation'),
            key: 'operation',
            hideInSearch: true,
            render: (text, record) => (
                <Button onClick={() => {
                    setSelectedMember(record);
                    setIsModalVisible(true);
                }}>
                    {translate('Detail')}
                </Button>
            ),
        }
    ];

    useEffect(() => {
        return () => {
            abortController.current.abort();
        }
    }, []);

    return (
        <div>
            <ProTable<IMemberInfoViewModel, IGetMemberInfoViewModelByMerchantRequest>
                columns={columns}
                actionRef={actionRef}
                formRef={formRef}
                ghost={true}
                cardBordered
                dataSource={memberInfoVMs}
                rowKey="id"
                pagination={{
                    total: totalCount,
                    pageSize: queryRequest.pageSize,
                    current: queryRequest.page,
                    showQuickJumper: true,
                    showSizeChanger: true,
                }}
                search={{
                    labelWidth: 'auto',
                    defaultCollapsed: false,
                    optionRender: (searchConfig, formProps, dom) => [
                        ...dom.reverse(),
                        <Button key="search" type="primary" onClick={handleSearch}>
                            {translate('Search')}
                        </Button>,
                    ],
                }}
                onSubmit={handleSearch}
                onChange={handleTableChange}
                form={{
                    initialValues: initialQueryRequest,
                    onValuesChange: (changedValues, allValues) => {
                        updateQueryStringWithoutSearch(allValues);
                    },
                }}
                toolBarRender={false}
                loading={isLoading}
            />

            <Modal
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <MemberDetail memberVM={selectedMember} />
            </Modal>
        </div>
    );
};

export default MemberManagePage;