export const MicroShopIdentityRoleConstant = {
    SystemAdmin: 'SystemAdmin',
    SystemOperator: 'SystemOperator',
    Developer: 'Developer',
    Merchant: 'Merchant',
    MerchantAssistant: 'MerchantAssistant',
}

export const MerchantRoleEnum = {
    /// <summary>
    /// 擁有者
    /// </summary>
    Owner: 'Owner',
    /// <summary>
    /// 業務
    /// </summary>
    Sales: 'Sales',
    /// <summary>
    /// 投資人 (看報表)
    /// </summary>
    Investor: 'Investor',
    /// <summary>
    /// 開發者 (客製化代碼異動)
    /// </summary>
    Developer: 'Developer',
}