import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { CopyrightOutlined } from '@ant-design/icons';
import { Flex, Layout } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const { Footer } = Layout;

const AppFooter: React.FC = () => {
    const buildTime = process.env.BUILD_TIME;
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    return (
        <Footer style={{
            backgroundColor: 'white',
            textAlign: 'center',
            position: 'relative'
        }}>
            <div>
                <CopyrightOutlined /> 2024 Domigo. All rights reserved.
            </div>
            <div style={{ margin: '5px', fontSize: '10px' }}>
                <a onClick={() => navigate(`/disclaimer`)} style={{ marginRight: '16px', cursor: 'pointer' }}>
                    {translate('Disclaimer')}
                </a>
            </div>
            <Flex
                justify="center"
                align="center">
                <div style={{ fontSize: '8px', color: 'gray' }}>
                    {buildTime}
                </div>
            </Flex>
        </Footer>
    );
};

export default AppFooter;
