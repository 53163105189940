import { MerchantWebsiteUpgradeLevel } from "@/enums/Enums";
import { IUpsertPortalStyleRequest, IDeletePortalStyleRequest } from "@/interfaces/Requests/Merchant";
import { IAlterMerchantPortalSettingRequest, IBackOfficeRegisterRequest as IBackOfficeRegistRequest, ICreateMerchantRequest, ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest, ICreatePaymentOrderRequest, ILoginRequest, IMerchantApplyRequest } from "@/interfaces/Requests/Requests";
import { IMerchant, IMerchantPortalOptionSettingDto } from "@/interfaces/Responses/Responses";
import { IMerchantApply } from "@/interfaces/Responses/SystemManager";
import { IThirdPartyPaymentCreateRespnse } from "@/interfaces/Responses/ThirdPartyPayment";
import { IOperationResult, IOperationResultT } from "@/Templates/interfaces/templatesInterfaces";
import useAPI from '@/Templates/lib/customHooks/useAPI';

export const useIsBackOfficeMemberApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/v1/Merchant/IsBackOfficeMember`, { signal });

export const useGetMerchantApplyByUserIdApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchantApply>>(`${process.env.BASE_API_URL}/api/v1/Merchant/GetMerchantApplyByUserId`, { signal });

export const useMerchantApplyApi = (params: IMerchantApplyRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Merchant/MerchantApply`, {
        method: 'POST',
        body: params,
        signal
    });

export const useCreateMerchantApi = (params: ICreateMerchantRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Merchant/CreateMerchant`, {
        method: 'POST',
        body: params,
        signal
    });

export const useMerchantLoginApi = (params: ILoginRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/v1/Merchant/Login`, {
        method: 'POST',
        body: params,
        signal
    });

export const useGetAccessibleMerchantsApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchant[]>>(`${process.env.BASE_API_URL}/api/v1/Merchant/GetAccessibleMerchants`, { signal });

export const useGetOwnMerchantsApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchant[]>>(`${process.env.BASE_API_URL}/api/v1/Merchant/GetOwnMerchants`, { signal });

export const useGetCanUpgradeMerchantWebsiteLevelsApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<MerchantWebsiteUpgradeLevel[]>>(`${process.env.BASE_API_URL}/api/v1/Merchant/GetCanUpgradeMerchantWebsiteLevels`, { signal });

export const useCreateTutorialUpgradeMerchantWebsiteLevelOrderApi = (params: ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/v1/Merchant/CreateTutorialUpgradeMerchantWebsiteLevelOrder`, {
        method: 'POST',
        body: params,
        signal
    });

export const useCreateUpgradeMerchantWebsiteLevelOrderApi = (params: ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/v1/Merchant/CreateUpgradeMerchantWebsiteLevelOrder`, {
        method: 'POST',
        body: params,
        signal
    });

export const useCreatePaymentOrderApi = (params: ICreatePaymentOrderRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<IThirdPartyPaymentCreateRespnse>>(`${process.env.BASE_API_URL}/api/v1/ThirdPartyPayment/CreateOrder`, {
        method: 'POST',
        body: params,
        signal
    });

export const useGetMerchantPortalSettingApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchantPortalOptionSettingDto>>(`${process.env.BASE_API_URL}/api/v1/Merchant/GetMerchantPortalSetting`, { signal });

export const useAlterMerchantPortalSettingApi = (params: IAlterMerchantPortalSettingRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/v1/Merchant/AlterMerchantPortalSetting`, {
        method: 'POST',
        body: params,
        signal
    });

export const useGetUserInfoEventCountApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<number>>(`${process.env.BASE_API_URL}/api/v1/Merchant/GetUserInfoEventCount`, { signal });

export const useAlterMerchantIconApi = (ImageLink: string, signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/v1/Merchant/AlterMerchantIcon`, {
        method: 'POST',
        body: { ImageLink },
        signal
    });

export const useAlterMerchantBrowserTabIconApi = (ImageLink: string, signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/v1/Merchant/AlterMerchantBrowserTabIcon`, {
        method: 'POST',
        body: { ImageLink },
        signal
    });

export const useIsEnableToUnSubscribeApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/v1/Merchant/IsEnableToUnSubscribe`, { signal });

export const useUpsertPortalStyleApi = (params: IUpsertPortalStyleRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Merchant/UpsertPortalStyle`, {
        method: 'POST',
        body: params,
        signal
    });

export const useMerchantRegistApi = (params: IBackOfficeRegistRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Merchant/Regist`, {
        method: 'POST',
        body: params,
        signal
    });

export const useDeletePortalStyleApi = (params: IDeletePortalStyleRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/v1/Merchant/DeletePortalStyle`, {
        method: 'POST',
        body: params,
        signal
    });