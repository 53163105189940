import queryString from 'query-string';
import { useEffect } from 'react';

export interface IThirdPartyTokenLoginFlowProps {
    tokenCallback: (token: string, method: string, state?: string) => void;
}

/**
 * 獲取第三方登入的token 後，呼叫tokenCallback
 */
const ThirdPartyTokenLoginFlow = (props: IThirdPartyTokenLoginFlowProps) => {
    useEffect(() => {
        const handleThirdPartyTokenLogin = async () => {
            const { token, method, state } = queryString.parse(window.location.search);
            try {
                if (token && method) {
                    props.tokenCallback(token as string, method as string, state as string);
                }
            } catch (error) {
                console.error('Error during third party login:', error);
            }
        };

        handleThirdPartyTokenLogin();
    }, []);

    return (
        <></>
    );
};

export default ThirdPartyTokenLoginFlow;
