import taiwanCitizenDigitalCertificate from '@/Templates/assets/invoice/TaiwanCitizenDigitalCertificate.jpeg';
import taiwanElectronicVehicle from '@/Templates/assets/invoice/TaiwanElectronicVehicle.png';
import { Checkbox, Form, Image, Input, Radio, Select } from "antd";
import { useEffect } from "react";

export interface TaiwanInvoiceFieldsProps {
    invoiceValues: any;
    setInvoiceValues: any;
    invoiceValidate: any;
    setInvoiceValidate: any;
}

export enum TaiwanInvoiceType {
    Electronic = 'Electronic',
    Donate = 'Donate',
    Paper = 'Paper'
}

export enum TaiwanInvoiceElectronicType {
    None = '',
    PersonalDevice = 'PersonalDevice',
    CitizenCertificate = 'CitizenCertificate'
}

// 定義發票值的類型
interface InvoiceValues {
    InvoiceType: TaiwanInvoiceType;
    DonateTo: string;
    ElectronicType: TaiwanInvoiceElectronicType;
    PersonalDeviceInput: string;
    CitizenCertificateInput: string;
    CompanyDetails: string;
    CompanyUnifiedNumber: string;
    CompanyTitle: string;
}

const TaiwanInvoiceFields = ({ invoiceValues, setInvoiceValues, invoiceValidate, setInvoiceValidate }: TaiwanInvoiceFieldsProps) => {
    // 初始化發票預設值
    useEffect(() => {
        setInvoiceValues({
            InvoiceType: TaiwanInvoiceType.Donate,
            DonateTo: '168001',
            ElectronicType: TaiwanInvoiceElectronicType.None,
            PersonalDeviceInput: '',
            CitizenCertificateInput: '',
            CompanyDetails: 'false',
            CompanyUnifiedNumber: '',
            CompanyTitle: ''
        } as InvoiceValues);
    }, []);

    // 驗證表單是否有效
    useEffect(() => {
        validateForm(invoiceValues).then(isValid => {
            console.log(isValid);
            setInvoiceValidate(isValid);
        });
    }, [invoiceValues]);

    // 通用輸入處理函數
    const handleInputChange = (e) => {
        const { name, value, type = 'text', checked = false } = e.target;
        const inputValue = type === 'checkbox' ? (checked ? 'true' : 'false') : value;

        // 清空不需要的資料
        const clearedValues = clearUnnecessaryData(name, inputValue);

        setInvoiceValues(prevValues => ({
            ...prevValues,
            ...clearedValues,
            [name]: inputValue,
        }));
    };

    // 清空不需要的資料
    const clearUnnecessaryData = (name, value) => {
        const clearedData: Partial<InvoiceValues> = {};
        if (name === 'InvoiceType') {
            if (value === 'Electronic') {
                clearedData.DonateTo = '';
                clearedData.CompanyDetails = 'false';
                clearedData.CompanyUnifiedNumber = '';
                clearedData.CompanyTitle = '';
                clearedData.ElectronicType = TaiwanInvoiceElectronicType.PersonalDevice;
            } else if (value === TaiwanInvoiceType.Donate) {
                clearedData.ElectronicType = TaiwanInvoiceElectronicType.None;
                clearedData.PersonalDeviceInput = '';
                clearedData.CitizenCertificateInput = '';
                clearedData.CompanyDetails = 'false';
                clearedData.CompanyUnifiedNumber = '';
                clearedData.CompanyTitle = '';
                clearedData.DonateTo = '168001';
            } else if (value === TaiwanInvoiceType.Paper) {
                clearedData.ElectronicType = TaiwanInvoiceElectronicType.None;
                clearedData.PersonalDeviceInput = '';
                clearedData.CitizenCertificateInput = '';
                clearedData.DonateTo = '';
            }
        } else if (name === 'ElectronicType') {
            if (value === TaiwanInvoiceElectronicType.PersonalDevice) {
                clearedData.CitizenCertificateInput = '';
            } else if (value === TaiwanInvoiceElectronicType.CitizenCertificate) {
                clearedData.PersonalDeviceInput = '';
            }
        } else if (name === 'CompanyDetails' && value === 'false') {
            clearedData.CompanyUnifiedNumber = '';
            clearedData.CompanyTitle = '';
        }
        return clearedData;
    };

    // 驗證表單數據
    const validateForm = async (values: InvoiceValues) => {
        try {
            if (values.InvoiceType === TaiwanInvoiceType.Electronic) {
                if (!values.ElectronicType) return false;
                if (values.ElectronicType === TaiwanInvoiceElectronicType.PersonalDevice && !/^\/\w{7}$/.test(values.PersonalDeviceInput.trim())) return false;
                if (values.ElectronicType === TaiwanInvoiceElectronicType.CitizenCertificate && !/^[A-Z]{2}\d{14}$/.test(values.CitizenCertificateInput.trim())) return false;
            } else if (values.InvoiceType === TaiwanInvoiceType.Donate && !values.DonateTo.trim()) {
                return false;
            } else if (values.InvoiceType === TaiwanInvoiceType.Paper) {
                if (values.CompanyDetails === 'true') {
                    if (!values.CompanyUnifiedNumber.trim() || !values.CompanyTitle.trim()) return false;
                }
            }
            return true;
        } catch (error) {
            return false;
        }
    };

    // 電子發票相關渲染
    const renderElectronicFields = () => (
        <>
            <Form.Item>
                <label>請選擇電子發票載具類型:</label>
                <Radio.Group
                    name="ElectronicType"
                    value={invoiceValues['ElectronicType']}
                    onChange={(e) => handleInputChange({ target: { name: 'ElectronicType', value: e.target.value, type: 'radio', checked: false } })}
                >
                    <Radio value={TaiwanInvoiceElectronicType.PersonalDevice}>個人載具</Radio>
                    <Radio value={TaiwanInvoiceElectronicType.CitizenCertificate}>自然人憑證</Radio>
                </Radio.Group>
            </Form.Item>

            {invoiceValues['ElectronicType'] === TaiwanInvoiceElectronicType.PersonalDevice && (
                <>
                    <Image src={taiwanElectronicVehicle} width={'300px'} alt="個人載具" />
                    <Form.Item>
                        <label>個人載具信息:</label>
                        <Input
                            name="PersonalDeviceInput"
                            value={invoiceValues['PersonalDeviceInput'] || ""}
                            onChange={handleInputChange}
                            placeholder="/OOOOOOO"
                        />
                    </Form.Item>
                </>
            )}

            {invoiceValues['ElectronicType'] === TaiwanInvoiceElectronicType.CitizenCertificate && (
                <>
                    <Image width={'300px'} src={taiwanCitizenDigitalCertificate} alt="自然人憑證" />
                    <Form.Item>
                        <label>自然人憑證信息:</label>
                        <Input
                            name="CitizenCertificateInput"
                            value={invoiceValues['CitizenCertificateInput'] || ""}
                            onChange={handleInputChange}
                            placeholder="TP03000001234567"
                        />
                    </Form.Item>
                </>
            )}
        </>
    );

    // 捐贈發票相關渲染
    const renderDonateFields = () => (
        <Form.Item>
            <label>捐贈單位名稱:</label>
            <Select
                value={invoiceValues['DonateTo']}
                onChange={value => handleInputChange({ target: { name: 'DonateTo', value, type: 'select', checked: false } })}
            >
                <Select.Option value="168001">OMG關懷社會愛心基金會</Select.Option>
            </Select>
        </Form.Item>
    );

    // 紙本發票相關渲染
    const renderPaperFields = () => (
        <>
            <Form.Item>
                <Checkbox
                    name="CompanyDetails"
                    checked={invoiceValues['CompanyDetails'] === 'true'}
                    onChange={handleInputChange}
                >
                    是否需要統編？
                </Checkbox>
            </Form.Item>

            {invoiceValues['CompanyDetails'] === 'true' && (
                <>
                    <Form.Item>
                        <label>統一編號:</label>
                        <Input
                            name="CompanyUnifiedNumber"
                            value={invoiceValues['CompanyUnifiedNumber'] || ""}
                            onChange={handleInputChange}
                            placeholder="請輸入統一編號"
                        />
                    </Form.Item>
                    <Form.Item>
                        <label>公司擡頭:</label>
                        <Input
                            name="CompanyTitle"
                            value={invoiceValues['CompanyTitle'] || ""}
                            onChange={handleInputChange}
                            placeholder="請輸入公司擡頭"
                        />
                    </Form.Item>
                </>
            )}
            <p>紙本發票將會隨商品一同寄出</p>
        </>
    );

    return (
        <div style={{ margin: '10px', display: 'flex', justifyContent: 'center' }}>
            <Form>
                <Form.Item>
                    <label>請選擇發票類型:</label>
                    <Radio.Group name="InvoiceType" value={invoiceValues['InvoiceType']} onChange={handleInputChange}>
                        <Radio value={TaiwanInvoiceType.Electronic}>電子發票載具</Radio>
                        <Radio value={TaiwanInvoiceType.Donate}>捐贈發票</Radio>
                        <Radio value={TaiwanInvoiceType.Paper}>紙本發票</Radio>
                    </Radio.Group>
                </Form.Item>

                {invoiceValues['InvoiceType'] === TaiwanInvoiceType.Electronic && renderElectronicFields()}
                {invoiceValues['InvoiceType'] === TaiwanInvoiceType.Donate && renderDonateFields()}
                {invoiceValues['InvoiceType'] === TaiwanInvoiceType.Paper && renderPaperFields()}
            </Form>
        </div>
    );
};

export default TaiwanInvoiceFields;
