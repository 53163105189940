import { LoginTypeEnum, ThirdPartyLoginProviders } from "@/enums/Enums";
import { IBackOfficeUserInfoViewModel } from "@/interfaces/Responses/User";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { ObjectHelper } from "@/Templates/helpers/ObjectComparisonHelper";
import { CopyOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Empty, Spin, Tabs, Typography, message } from "antd";
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useContext, useEffect } from "react";

// 初始化 dayjs 插件
dayjs.extend(utc);
dayjs.extend(timezone);

const { Text } = Typography;
const { TabPane } = Tabs;

const UserInfoPage: React.FC = () => {
    const { messageApi, getUserInfoMutation } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);

    useEffect(() => {
        getUserInfoMutation.mutate();
    }, []);

    const handleRefresh = () => {
        getUserInfoMutation.mutate();
    };

    const isEmptyOrWhitespace = (str: BigInt | string | undefined): boolean => {
        return str === undefined || str.toString().trim() === '';
    };

    const renderFieldValue = (value: BigInt | string | undefined | ThirdPartyLoginProviders | Date): React.ReactNode => {
        if (value === null || value === undefined) {
            return <Text type="secondary">{translate('Unset')}</Text>;
        }

        if (typeof value === 'string' && dayjs(value).isValid()) {
            const date = dayjs(value);
            const utcOffset = date.utcOffset();
            const utcHours = Math.abs(Math.floor(utcOffset / 60));
            const utcSign = utcOffset >= 0 ? '+' : '-';

            return `${date.format('YYYY-MM-DD HH:mm:ss')} (UTC${utcSign}${utcHours.toString().padStart(2, '0')}:00)`;
        }

        if (typeof value === 'string') {
            return isEmptyOrWhitespace(value) ? <Text type="secondary">{translate('Unset')}</Text> : translate(value);
        }

        if (ObjectHelper.isBigInt(value)) {
            return value.toString();
        }

        if (value instanceof Date) {
            return value.toLocaleString('zh-TW');
        }

        if (Object.values(ThirdPartyLoginProviders).includes(value as ThirdPartyLoginProviders)) {
            return translate(ThirdPartyLoginProviders[value as ThirdPartyLoginProviders]) || <Text type="secondary">{translate('Unset')}</Text>;
        }

        return <Text type="secondary">{translate('Unset')}</Text>;
    };

    const handleCopyUserId = (userId: string) => {
        navigator.clipboard.writeText(userId).then(() => {
            message.success(translate('Copied to clipboard'));
        }).catch(() => {
            message.error(translate('Failed to copy User ID'));
        });
    };

    if (getUserInfoMutation.isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    if (getUserInfoMutation.isError) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Text type="danger">{translate('Error loading user information')}</Text>
                <br />
                <Button onClick={handleRefresh} icon={<ReloadOutlined />} style={{ marginTop: '20px' }}>
                    {translate('Try Again')}
                </Button>
            </div>
        );
    }

    const userInfoData: IBackOfficeUserInfoViewModel | undefined = getUserInfoMutation.data?.result;

    return (
        <div style={{ padding: '24px' }}>
            <Card
                title={`${translate('User')} ${translate('Information')}`}
                extra={<Button icon={<ReloadOutlined />} onClick={handleRefresh}>{translate('Refresh')}</Button>}
                style={{ maxWidth: 500, margin: '0 auto 20px' }}
            >
                {userInfoData ? (
                    <>
                        <p>
                            <strong>{translate('User ID')}:</strong>{' '}
                            {renderFieldValue(userInfoData.memberId)}{' '}
                            {!isEmptyOrWhitespace(userInfoData.memberId) && (
                                <CopyOutlined
                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    onClick={() => handleCopyUserId(userInfoData.memberId.toString())}
                                />
                            )}
                        </p>
                        <p><strong>{translate('User Name')}:</strong> {renderFieldValue(userInfoData.userName)}</p>
                        <p><strong>{translate('LoginType')}:</strong> {renderFieldValue(userInfoData.loginType)}</p>
                        {userInfoData.loginType === LoginTypeEnum.ThirdPartyLogin
                            &&
                            <p>
                                <strong>{translate('ThirdPartyLoginProviders')}:</strong> {renderFieldValue(userInfoData.thirdPartyLoginProviders)}
                            </p>
                        }
                        <p><strong>{translate('Email')}:</strong> {renderFieldValue(userInfoData.email)}</p>
                        <p><strong>{translate('Phone Number')}:</strong> {renderFieldValue(userInfoData.phoneNumber)}</p>
                        <p><strong>{translate('Create Date')}:</strong> {renderFieldValue(userInfoData.createDate.toString())}</p>
                    </>
                ) : (
                    <Text type="secondary">{translate('No user information available')}</Text>
                )}
            </Card>
            <Tabs defaultActiveKey="1" centered style={{ maxWidth: 800, margin: '0 auto' }}>
                <TabPane tab={translate('Messages')} key="2">
                    <Empty description={
                        translate('No data')
                    }>
                    </Empty>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default UserInfoPage;