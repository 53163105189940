import { ThirdPartyPlatformServiceProvider } from "@/enums/ThirdPartyPlatformService";

export interface IThirdPartyModule {
    executable: boolean;
    inputParameters: IInputParameter[]
}

export interface IInputParameter {
    [key: string]: 'string' | 'number' | 'boolean';
}

const ThirdPartyPlatformServiceProviderInputs: Record<ThirdPartyPlatformServiceProvider, Record<string, IThirdPartyModule>> = {
    [ThirdPartyPlatformServiceProvider.Facebook]: {
        MessageGroup: {
            executable: true,
            inputParameters: [
                { 'Enabled': 'boolean' },
                { 'ShowJoinIcon': 'boolean' },
            ]
        }
    },
    [ThirdPartyPlatformServiceProvider.Line]: {
        MessageGroup:
        {
            executable: true,
            inputParameters: [
                { 'Enabled': 'boolean' },
                { 'ShowJoinIcon': 'boolean' },
                { 'ChannelID': 'string' },
                { 'ChannelAccessToken': 'string' },
                { 'BotBasicID': 'string' }, //用於生成加入群組的連結
            ],
        },
    },
    [ThirdPartyPlatformServiceProvider.Google]: {
        GA:
        {
            executable: false,
            inputParameters: [
                { 'Enabled': 'boolean' },
                { 'TrackingId': 'string' },
            ]
        }
    }
}

export default ThirdPartyPlatformServiceProviderInputs;