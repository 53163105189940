import { DeviceType, MerchantPortalStyleSettingType, MerchantPortalStyleShopPages } from '@/Templates/enums/templateEnums';
import { LaptopOutlined, MobileOutlined, TabletOutlined } from '@ant-design/icons';
import { Button, Col, Empty, Flex, Row, Select } from 'antd';

import PreviewContainer from './PreviewContainer';
import StyleConfigSelector from './StyleConfigSelector';
import { BasePreviewProps } from './types';
import { IMerchantPortalStyleSetting } from '@/Templates/interfaces/templatesInterfaces';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { useGetMerchantPortalStyleSettingsApi } from '@/lib/api/portals';
import { useMutation } from 'react-query';

interface PagePreviewProps extends BasePreviewProps {
    selectedPageType: MerchantPortalStyleShopPages;
    setSelectedPageType: (value: MerchantPortalStyleShopPages) => void;
    PageTemplateComponent: React.ComponentType<any> | null;
    mockPageProps: any;
    styleState: any;
    handleStyleChange: (type: 'component' | 'page', value: number) => void;
    handleConfigChange: (type: 'component' | 'page', key: string, value: string) => void;
    abortController: React.MutableRefObject<AbortController | null>;
}

const PagePreview: React.FC<PagePreviewProps> = ({
    containerwidth,
    previewdevicetype,
    windowdevicetype,
    previewContainerRef,
    selectedPageType,
    setSelectedPageType,
    PageTemplateComponent,
    mockPageProps,
    styleState,
    handleStyleChange,
    handleConfigChange,
    handlePreviewDeviceChange,
    getPreviewWidth,
    showSeleceTestDataDrawer,
    translate,
    abortController
}) => {
    const { merchantId } = useContext(GlobalContext);
    const [selectedPortalStyleSetting, setSelectedPortalStyleSetting] = useState<IMerchantPortalStyleSetting | undefined>(undefined);
    const merchantPortalStyleSettingsMutation = useMutation("merchantPortalStyleSettingsMutation", async (merchantId: BigInt) => {
        return await useGetMerchantPortalStyleSettingsApi(merchantId);
    });
    useEffect(() => {
        merchantPortalStyleSettingsMutation.mutate(merchantId!);
    }, []);
    useEffect(() => {
        if (merchantPortalStyleSettingsMutation.data?.result) {
            setSelectedPortalStyleSetting(merchantPortalStyleSettingsMutation?.data?.result?.find((setting: IMerchantPortalStyleSetting) =>
                setting.type === MerchantPortalStyleSettingType.Pages &&
                setting.name === selectedPageType.toString()));
        }
    }, [merchantPortalStyleSettingsMutation.data]);

    return (
        <div>
            <Row style={{ marginBottom: 16 }}>
                <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24} style={{ marginBottom: windowdevicetype === DeviceType.Desktop ? 0 : 16 }}>
                    <Flex justify="center" align="center">
                        <Select
                            style={{ width: 200 }}
                            value={selectedPageType}
                            onChange={(value: MerchantPortalStyleShopPages) =>
                                setSelectedPageType(value)
                            }
                        >
                            {Object.values(MerchantPortalStyleShopPages).map((page) => (
                                <Select.Option key={page} value={page}>
                                    {translate(page)}
                                </Select.Option>
                            ))}
                        </Select>
                    </Flex>
                </Col>
                <Col span={windowdevicetype === DeviceType.Desktop ? 12 : 24}>
                    <Flex justify="center" align="center">
                        <Button
                            icon={<MobileOutlined />}
                            onClick={() => handlePreviewDeviceChange(DeviceType.Mobile)}
                            type={previewdevicetype === DeviceType.Mobile ? 'primary' : 'default'}
                        >
                            {translate('Mobile')}
                        </Button>
                        <Button
                            icon={<TabletOutlined />}
                            onClick={() => handlePreviewDeviceChange(DeviceType.LapTop)}
                            type={previewdevicetype === DeviceType.LapTop ? 'primary' : 'default'}
                        >
                            {translate('Laptop')}
                        </Button>
                        <Button
                            icon={<LaptopOutlined />}
                            onClick={() => handlePreviewDeviceChange(DeviceType.Desktop)}
                            type={previewdevicetype === DeviceType.Desktop ? 'primary' : 'default'}
                        >
                            {translate('Desktop')}
                        </Button>
                    </Flex>
                </Col>
            </Row>
            <PreviewContainer
                ref={previewContainerRef}
                containerwidth={containerwidth}
                previewwidth={getPreviewWidth()}
                previewdevicetype={previewdevicetype}
                windowdevicetype={windowdevicetype}
            >
                <div>
                    {PageTemplateComponent ? (
                        mockPageProps ? (
                            <PageTemplateComponent {...mockPageProps} />
                        ) : (
                            <Empty description={translate('No props')} />
                        )
                    ) : (
                        <Empty description={translate('No page template')} />
                    )}
                </div>
            </PreviewContainer>

            <Flex justify="center" align="center" style={{ padding: '10px' }} gap="20px">
                {showSeleceTestDataDrawer()}
                <StyleConfigSelector
                    portalStyleSetting={selectedPortalStyleSetting}
                    selectedStyle={styleState.page.style}
                    styleConfig={styleState.page.styleConfig}
                    configFields={styleState.page.configFields}
                    onStyleChange={(value) => handleStyleChange('page', value)}
                    onConfigChange={(key, value) => handleConfigChange('page', key, value)}
                    styleOptions={[
                        { value: 0, label: translate('Style 1') },
                        { value: 1, label: translate('Style 2') },
                    ]}
                    abortSignal={abortController.current?.signal}
                />
            </Flex>
        </div>
    );
};

export default PagePreview;