export class GenereateHelper {
    private Snowflake: Snowflake;
    constructor(workerId: number = 0, epoch: number = 0) {
        this.Snowflake = new Snowflake(workerId = 0, epoch);
    }
    public getSnowflakeId(): string {
        return this.Snowflake.nextId();
    }
    public getSnowflakeIdBigInt(): BigInt {
        return BigInt(this.Snowflake.nextId());
    }
}

export class Snowflake {
    private workerId: number;
    private epoch: number;
    private sequence: number;
    private lastTimestamp: number;

    constructor(workerId: number, epoch: number = 0) {
        this.workerId = workerId;
        this.epoch = epoch;
        this.sequence = 0;
        this.lastTimestamp = -1;
    }

    public nextId(): string {
        let timestamp = Date.now();
        if (timestamp < this.lastTimestamp) {
            throw new Error('Clock moved backwards!');
        }

        if (timestamp === this.lastTimestamp) {
            this.sequence = (this.sequence + 1) & 0xFFF;
            if (this.sequence === 0) {
                // Sequence overflow, wait till next millisecond
                while (timestamp <= this.lastTimestamp) {
                    timestamp = Date.now();
                }
            }
        } else {
            this.sequence = 0;
        }

        this.lastTimestamp = timestamp;

        const id = ((BigInt(timestamp - this.epoch) << BigInt(22)) |
            (BigInt(this.workerId) << BigInt(12)) |
            BigInt(this.sequence));

        return id.toString();
    }
}
