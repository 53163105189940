
import { MerchantPortalStyleSettingType } from "@/Templates/enums/templateEnums";
import { ItemCardTemplateProps } from "@/Templates/TemplateProps";
import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import LoadingComponent from "./LoadingCompoent";
const ItemCard: React.FC<ItemCardTemplateProps> = React.memo(({
    BASE_PATH,
    item,
    merchantId,
    deviceType,
    merchantPortalOptionSettingMutation,
    merchantPortalStyleSettingsMutation,
    translate,
    messageApi,
    config,
    isPreviewMode,
    notificationApi,
    navBarHeight,
    navBarGap
}) => {
    const portalStyle = merchantPortalStyleSettingsMutation.data?.result?.find(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'ItemCard');
    //dynamic import
    const TemplateComponent = useMemo(() => {
        return dynamic<ItemCardTemplateProps>(() =>
            import(`../../Templates/Shop/Components/ItemCard/Template_${portalStyle?.style || '0'}.tsx`),
            {
                loading: () => <LoadingComponent />,
                ssr: false
            }
        );
    }, [portalStyle]);

    return <TemplateComponent
        navBarHeight={navBarHeight}
        navBarGap={navBarGap}
        BASE_PATH={BASE_PATH}
        item={item}
        merchantId={merchantId}
        deviceType={deviceType}
        messageApi={messageApi}
        isloading={false}
        isPreviewMode={isPreviewMode}
        config={portalStyle?.styleSetting || {}}
        translate={translate}
        merchantPortalStyleSettingsMutation={merchantPortalStyleSettingsMutation}
        merchantPortalOptionSettingMutation={merchantPortalOptionSettingMutation}
        notificationApi={notificationApi}
    />;
});

export default ItemCard;