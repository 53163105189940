import { isEqual } from 'lodash';

export class ObjectHelper {

    static isBigInt(value: any): boolean {
        // 檢查是否為 BigNumber2 物件
        if (value && typeof value === 'object' &&
            's' in value && 'e' in value && 'c' in value) {
            return true;
        }
        // 檢查原生 bigint
        return typeof value === 'bigint' ||
            (typeof value === 'number' && value % 1 === 0);
    }

    /**
     * 比較兩個對象是否相等
     * @param obj1 第一個對象
     * @param obj2 第二個對象
     * @returns 如果對象相等返回 true，否則返回 false
     */
    static areObjectsEqual(obj1: any, obj2: any): boolean {
        return isEqual(obj1, obj2);
    }

    /**
     * 深度比較兩個對象
     * @param obj1 第一個對象
     * @param obj2 第二個對象
     * @returns 如果對象完全相等返回 true，否則返回 false
     */
    static deepCompare(obj1: any, obj2: any): boolean {
        // 檢查兩個參數是否都是對象
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return obj1 === obj2;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // 如果鍵的數量不同，對象就不相等
        if (keys1.length !== keys2.length) {
            return false;
        }

        // 遍歷所有鍵，比較值
        for (const key of keys1) {
            if (!obj2.hasOwnProperty(key)) {
                return false;
            }

            // 遞歸比較嵌套對象
            if (!this.deepCompare(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    /**
     * 比較兩個對象的特定屬性
     * @param obj1 第一個對象
     * @param obj2 第二個對象
     * @param properties 要比較的屬性數組
     * @returns 如果指定的屬性都相等返回 true，否則返回 false
     */
    static compareSpecificProperties(obj1: any, obj2: any, properties: string[]): boolean {
        for (const prop of properties) {
            if (!this.deepCompare(obj1[prop], obj2[prop])) {
                return false;
            }
        }
        return true;
    }

    /**
    * 遞迴處理: 若為 Object，將所有屬性按 key 名稱排序；
    * 若為 Array，則對每個元素做同樣處理；
    * 其他型態 (字串、數字、null...) 直接回傳原值。
    */
    static sortObjectKeys<T>(input: T): T {
        // 如果是陣列，對裡面每個項目做排序
        if (Array.isArray(input)) {
            return input.map((item) => this.sortObjectKeys(item)) as unknown as T;
        }

        // 若判斷到 BigNumber2 物件，直接回傳
        if (this.isBigInt(input)) {
            return input;
        }

        // 如果是物件，將 key 排序後重新組成新物件
        if (input !== null && typeof input === 'object') {
            const keys = Object.keys(input);
            const sortedKeys = keys.sort();
            const newObj: any = {};
            for (const key of sortedKeys) {
                newObj[key] = this.sortObjectKeys((input as any)[key]);
            }
            return newObj;
        }

        // 其他型別不處理
        return input;
    }
}
