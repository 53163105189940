import { IAlterMerchantThirdPartyLogisticSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyLogisticSettingViewModel } from "@/interfaces/Responses/Responses";
import { useAlterMerchantThirdPartyLogisticSettingApi, useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi } from "@/lib/api/thirdPartyLogistics";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Button, Flex, Modal, Space, Switch, Tag } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import AddLogistic, { IAddLogisticSettingRef } from "./AddLogistic";
import EditLogistic, { IEditLogisticSettingRef } from "./EditLogistic";

export interface ILogisticSettingListProps { }
export interface ILogisticSettingListRef {
}

const Row = (props: {
    row: IBackOfficeMerchantThirdPartyLogisticSettingViewModel,
    onEdit: (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => void,
    onToggleDisable: (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel, isDisable: boolean) => void,
    isLoading: boolean
}) => {
    const [open, setOpen] = useState(false);
    const { translate } = useContext(TranslationContext);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <Space>
                        {!props.row.isReleaseNow && (
                            <Tag color="red" icon={<ExclamationCircleOutlined />}>
                                {translate('Under Maintenance')}
                            </Tag>
                        )}
                        <span>{props.row.providerName}</span>
                        {props.row.isSupportedForwardFlow && (
                            <Tag color="blue">
                                {translate('Forward', 'Logistic')}
                            </Tag>
                        )}
                        {props.row.isSupportedReverseFlow && (
                            <Tag color="orange">
                                {translate('Reverse', 'Logistic')}
                            </Tag>
                        )}
                    </Space>
                </TableCell>
                <TableCell align="center">
                    {!props.row.isDisable && (
                        <Tag color="success">
                            {translate('Enable')}
                        </Tag>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className="p-4">
                            <Space direction="vertical" className="w-full">
                                <Flex justify="center" align="center" gap={10}>
                                    <span>{translate('Enable')}:</span>
                                    <Switch
                                        loading={props.isLoading}
                                        checked={!props.row.isDisable}
                                        onChange={(checked) => props.onToggleDisable(props.row, !checked)}
                                    />
                                </Flex>
                                <Flex justify="center" align="center">
                                    <Button onClick={() => props.onEdit(props.row)}>
                                        {translate('Edit')}
                                    </Button>
                                </Flex>
                            </Space>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const LogisticSettingList = React.forwardRef((props: ILogisticSettingListProps, ref: React.ForwardedRef<ILogisticSettingListRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const abortController = React.useRef(new AbortController());
    const addRef = useRef<IAddLogisticSettingRef>();
    const editRef = useRef<IEditLogisticSettingRef>();
    const [merchantThirdPartySettings, setMerchantThirdPartySettings] = useState<IBackOfficeMerchantThirdPartyLogisticSettingViewModel[]>([]);

    const { data, mutate, isLoading } = useMutation(async () => await useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi(abortController.current.signal), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMerchantThirdPartySettings(response.result || []);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: alterMutate, isLoading: alterMutateIsLoading } = useMutation(async (request: IAlterMerchantThirdPartyLogisticSettingRequest) => await useAlterMerchantThirdPartyLogisticSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                refresh();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const [selectedEditSetting, setSelectedEditSetting] = useState<IBackOfficeMerchantThirdPartyLogisticSettingViewModel | undefined>(undefined);
    const handleToggleDisable = (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel, isDisable: boolean) => {
        alterMutate({
            settingId: record.thirdPartySettingId,
            setting: record.merchantSettings,
            isDisabled: isDisable
        });
    };
    const [openAddSetting, setOpenAddSetting] = useState(false);

    const handleEdit = (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => {
        setSelectedEditSetting(record);
    };

    const refresh = () => {
        mutate();
    };

    useEffect(() => {
        abortController.current = new AbortController();
        refresh();
        return () => {
            abortController.current.abort();
        }
    }, []);

    return (
        <div style={{ margin: '16px' }}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="center">{translate('Provider')}</TableCell>
                            <TableCell align="center">{translate('Status')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {merchantThirdPartySettings.map((row) => (
                            <Row
                                key={row.id.toString()}
                                row={row}
                                onEdit={handleEdit}
                                onToggleDisable={handleToggleDisable}
                                isLoading={isLoading || alterMutateIsLoading}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button
                type="dashed"
                onClick={() => setOpenAddSetting(true)}
                block
                icon={<PlusOutlined />}
                style={{ marginTop: '16px' }}
            >
                {translate('Add new setting')}
            </Button>

            <Modal
                open={selectedEditSetting !== undefined}
                afterOpenChange={(open) => {
                    if (!open)
                        editRef.current?.onClose();
                    else
                        editRef.current?.onOpen(selectedEditSetting!);
                }}
                onCancel={() => setSelectedEditSetting(undefined)}
                footer={null}
            >
                <EditLogistic
                    ref={editRef}
                    onRefresh={() => refresh()}
                    onClose={() => setSelectedEditSetting(undefined)}
                />
            </Modal>
            <Modal
                open={openAddSetting}
                afterOpenChange={(open) => {
                    if (open) addRef.current?.refresh();
                }}
                onCancel={() => setOpenAddSetting(false)}
                footer={null}
            >
                <AddLogistic
                    ref={addRef}
                    ownRecords={merchantThirdPartySettings}
                    onRefresh={() => refresh()}
                    onClose={() => setOpenAddSetting(false)}
                />
            </Modal>
        </div>
    );
});

export default LogisticSettingList;
